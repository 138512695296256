import React from "react";
import { classNames } from "../utils/clsx";
import { Helmet } from "react-helmet";
import {Layout} from "../componentsV3/layout/Layout";

export default function Privacy() {
  return (
    <Layout>
      <div>
        <Helmet>
          <title>Alpha.art | Privacy Policy</title>
        </Helmet>
        <div className="max-w-4xl mx-auto py-16 px-4 sm:py-24 sm:px-6">
          <h2 className="font-bold text-xl">Privacy Policy</h2>
          <div className="pt-2 md-text">
            <p>Version 1.0</p>
            <p>Effective Date: October 27, 2021</p>
            <p>
              AlphaArt (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) values
              your privacy. We have prepared this Privacy Policy to describe how
              we collect, use, and disclose information that we obtain about
              visitors to our website at{" "}
              <a href="https://alpha.art/">https://alpha.art/</a> (the
              &quot;Site&quot;), about the registered Site users, the services
              available through our Site, including any mobile applications and
              browser extensions (collectively, the &quot;Services&quot;), and how
              we use and disclose that information.
            </p>
            <p>
              By visiting the Site, or using any of our Services, you agree that
              your personal information will be handled as described in this
              Policy. Your use of our Site or Services, and any dispute over
              privacy, is subject to this Policy (including any applicable
              changes) and an applicable Terms of Use, including its applicable
              limitations on damages and provisions for the resolution of
              disputes.
            </p>
            <h4 id="1-types-of-data-we-collect-">1. Types of Data We Collect.</h4>
            <p>
              We collect information about you directly from you and from third
              parties, as well as automatically through your use of our Site or
              Services.
            </p>
            <ul>
              <li>a. Information We Collect Directly From You.</li>
            </ul>
            <p>
              You may browse certain areas of the Site without registering with us
              or providing us personal information.
            </p>
            <p>
              We collect email only to notify you of the trade offers. Your email
              address won&#39;t be shared with any third party.
            </p>
            <p>
              If you provide us feedback or contact us via e-mail, we will collect
              user name and e-mail address, as well as any other content included
              in the e-mail, in order to send you a reply.
            </p>
            <p>
              In addition, if you are providing personal information for third
              parties in connection with using our Services, you are responsible
              for ensuring that you have all required permissions and consents to
              provide such personal information to us for use in connection with
              the Services and that our use of such personal information to
              provide the Services does not violate any applicable law, rule,
              regulation or order.
            </p>
            <ul>
              <li>b. Information We Collect Automatically.</li>
            </ul>
            <p>
              We may automatically collect the following information about your
              use of our Site or Services through cookies and other technologies:
              your domain name; your browser type and operating system; web pages
              you view; links you click; your IP address; the length of time you
              visit our Site or use our Services; and the referring URL, or the
              webpage that led you to our Site. We may combine this information
              with other information that we have collected about you, including,
              where applicable, your username, email address, Solana addres,
              wallet type, and/or a date/time stamp for your visit.
            </p>
            <ul>
              <li>c. Information Collected from Third Party Companies.</li>
            </ul>
            <p>
              We may receive Personal and/or Anonymous Data about you from
              companies that provide our Services by way of a co-branded or
              private-labeled website or companies that offer their products
              and/or services on our Service. In particular, Phantom and other
              wallets provide us with your Solana address and certain other
              information you choose to share with Phantom and other wallets.
              These third party companies may supply us with Personal Data. We may
              add this to the information we have already collected from you via
              our Service in order to improve it. We do not collect Personal Data
              automatically, but we may tie the information that we collect
              automatically to Personal Data about you that we collect from other
              sources or that you provide to us.
            </p>
            <h4 id="2-use-of-your-personal-data">2. Use of Your Personal Data</h4>
            <ul>
              <li>
                <p>
                  a) General Use. In general, Personal Data you submit to us is
                  used either to respond to requests that you make, or to aid us
                  in serving you better. We use your Personal Data in the
                  following ways:
                </p>
                <ul>
                  <li>
                    I. facilitate the creation of and secure your Account on our
                    network;
                  </li>
                  <li>II. identify you as a user in our system;</li>
                  <li>III. provide improved administration of our Service;</li>
                  <li>
                    IV. provide the Services you request, including but not
                    limited to facilitating your cryptocurrency transactions
                    through Phantom (
                    <a href="https://phantom.app/">https://phantom.app/</a>) or
                    other wallets;
                  </li>
                  <li>
                    V. improve the quality of experience when you interact with
                    our Service;
                  </li>
                  <li>
                    VI. send you a welcome e-mail to verify ownership of the
                    e-mail address provided when your Account was created;
                  </li>
                  <li>
                    VII protect you and other users from any conduct that violates
                    the Terms of Use or to prevent abuse or harassment of any
                    user;
                  </li>
                  <li>
                    VIII. display your username next to the digital assets you
                    wish to sell on the Website;
                  </li>
                  <li>
                    IX. send you administrative e-mail notifications, such as
                    security or support and maintenance advisories;
                  </li>
                  <li>
                    X. send you e-mail notifications related to actions on
                    Service, including notifications of offers on your digital
                    assets;
                  </li>
                  <li>
                    XI. respond to your inquiries related to employment
                    opportunities or other requests;
                  </li>
                  <li>
                    XII. send newsletters, surveys, offers, and other promotional
                    materials related to our Services and for other marketing
                    purposes of AlphaArt.
                  </li>
                </ul>
                <p>
                  We may use your email address to contact you about our own and
                  third parties&#39; goods and services that may be of interest to
                  you.
                </p>
              </li>
              <li>
                <p>
                  b) Anonymous Data. We may create Anonymous Data records from
                  Personal Data by excluding information (such as your email
                  address) that makes the data personally identifiable to you. We
                  use this Anonymous Data to analyze request and usage patterns so
                  that we may enhance the content of our Services and improve
                  Service navigation. We reserve the right to use Anonymous Data
                  for any purpose and to disclose Anonymous Data to third parties
                  without restriction.
                </p>
              </li>
            </ul>
            <h4 id="3-disclosure-of-your-personal-data-">
              3. Disclosure of Your Personal Data.
            </h4>
            <p>
              We disclose your Personal Data as described below and as described
              elsewhere in this Privacy Policy.
            </p>
            <ul>
              <li>
                a. Third Party Service Providers. We may share your Personal Data
                with third party service providers to: provide you with the
                Services that we offer you through our Service; to conduct quality
                assurance testing; to facilitate creation of accounts; to provide
                technical support; and/or to provide other services to the
                AlphaArt.
              </li>
              <li>
                b. Corporate Restructuring. We may share some or all of your
                Personal Data in connection with or during negotiation of any
                merger, financing, acquisition or dissolution transaction or
                proceeding involving sale, transfer, divestiture, or disclosure of
                all or a portion of our business or assets. In the event of an
                insolvency, bankruptcy, or receivership, Personal Data may also be
                transferred as a business asset. If another company acquires our
                company, business, or assets, that company will possess the
                Personal Data collected by us and will assume the rights and
                obligations regarding your Personal Data as described in this
                Privacy Policy.
              </li>
              <li>
                c. As Legally Required. Regardless of any choices you make
                regarding your Personal Data (as described below), AlphaArt may
                disclose Personal Data if it believes in good faith that such
                disclosure is necessary (a) in connection with any legal
                investigation; (b) to comply with relevant laws or to respond to
                subpoenas or warrants served on AlphaArt; (c) to protect or defend
                the rights or property of AlphaArt or users of the Service; and/or
                (d) to investigate or assist in preventing any violation or
                potential violation of the law, this Privacy Policy, or our Terms
                of Use.
              </li>
              <li>
                d. Other Disclosures. We may also disclose your Personal Data, to
                fulfill the purpose for which you provide it; for any other
                purpose disclosed by us when you provide it; or with your consent.
                We do not sell your Personal Data.
              </li>
            </ul>
            <h4 id="4-third-party-websites-">4. Third Party Websites.</h4>
            <p>
              Our Service may contain links to third party websites. When you
              click on a link to any other website or location, you will leave our
              Service and go to another site, and another entity may collect
              Personal Data or Anonymous Data from you. You may also find
              marketplaces powered by the Service on other websites. We have no
              control over, do not review, and cannot be responsible for, these
              outside websites or their content. Please be aware that the terms of
              this Privacy Policy do not apply to these outside websites or
              content, or to any collection of your Personal Data after you click
              on links to such outside websites. We encourage you to read the
              privacy policies of every website you visit. The links to third
              party websites or locations are for your convenience and do not
              signify our endorsement of such third parties or their products,
              content or websites.
            </p>
            <h4 id="5-third-party-wallet-extensions-">
              5. Third-Party Wallet Extensions.
            </h4>
            <p>
              For conducting cryptocurrency transactions we use third-party
              electronic wallet extensions such as (but not limited to) Phantom;
              your interactions with Phantom and/or any third-party electronic
              wallet extensions are governed by the applicable privacy policies.
              In the case of Phantom, its privacy policy is available{" "}
              <a href="https://phantom.app/privacy">here</a>.
            </p>
            <h4 id="6-your-choices-regarding-information-">
              6. Your Choices Regarding Information.
            </h4>
            <p>
              You have several choices regarding the use of information on our
              Services:
            </p>
            <ul>
              <li>
                a) Email Communications. We will periodically send you free,
                opt-in newsletters and e-mails that directly promote the use of
                our Service. When you receive newsletters or promotional
                communications from us, you may indicate a preference to stop
                receiving further communications from us and you will have the
                opportunity to &quot;opt-out&quot; by following the unsubscribe
                instructions provided in the e-mail you receive or by contacting
                us directly (please see contact information in paragraph (1)).
                Despite your indicated e-mail preferences, we may send you
                occasional service related communications, including notices of
                updates to our Terms of Use or Privacy Policy.
              </li>
              <li>
                b) If you decide at any time that you no longer wish to accept
                Cookies from our Service for any of the purposes described above,
                then you can instruct your browser, by changing its settings, to
                stop accepting Cookies or to prompt you before accepting a Cookie
                from the websites you visit. Consult your browser&#39;s technical
                information. If you do not accept Cookies, however, you may not be
                able to use all portions of the Service or all functionality of
                the Service. If you have any questions about how to disable or
                modify Cookies, please let us know at the address given in
                paragraph (1).
              </li>
            </ul>
            <h4 id="7-data-access-and-control-">7. Data Access and Control.</h4>
            <p>
              You can view, access, or edit your username for many aspects of the
              Service via your profile page. You can always choose whether you
              want to receive marketing communications from us. You can also opt
              out from receiving marketing communications from us by using the
              opt-out link on the communication, or by visiting your update
              profile page. We may retain an archived copy of your records as
              required by law or for legitimate business purposes.
            </p>
            <h4 id="8-data-retention-">8. Data Retention.</h4>
            <p>
              We may retain your wallet&#39;s activity as long as you continue to
              use the Service with that wallet, have an account with us, or for as
              long as is necessary to fulfill the purposes outlined in this
              Privacy Policy. You can ask to close your account by contacting us
              as described above, and we will delete your username associated with
              that wallet on request.
            </p>
            <h4 id="9-data-protection-">9. Data Protection.</h4>
            <p>
              We care about the security of your information and use physical,
              administrative, and technological safeguards to preserve the
              integrity and security of all information collected through our
              website. However, no security system is impenetrable and we cannot
              guarantee the security of our systems 100%. In the event that any
              information under our control is compromised as a result of a breach
              of security, we will take steps to investigate the situation and,
              where appropriate, notify those individuals whose information may
              have been compromised and take other steps, in accordance with any
              applicable laws and regulations.
            </p>
            <h4 id="10-changes-to-this-privacy-policy-">
              10. Changes to This Privacy Policy.
            </h4>
            <p>
              This Privacy Policy may be updated from time to time for any reason.
              We will notify you of any changes to our Privacy Policy by posting
              the new Privacy Policy at{" "}
              <a href="/privacy">https://alpha.art/privacy</a>. The date the
              Privacy Policy was last revised is identified at the beginning of
              this Privacy Policy. You are responsible for ensuring we have an
              up-to-date active and deliverable email address for you, and for
              periodically visiting our Service and this Privacy Policy to check
              for any changes.
            </p>
            <h4 id="11-questions-">11. Questions.</h4>
            <p>
              If you have questions about the privacy aspects of our Site or
              Services or would like to make a complaint, please contact us at
              support@alpha.art.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

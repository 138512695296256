import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { addNotification } from "../utils/alert";
import { getUserProgramAccounts } from "./app/utils";
import { store } from "./store";
import { WalletContextState } from "@solana/wallet-adapter-react";

export type TWallet = "Sollet" | "Phantom" | "Solflare";
const WalletKey = "walletOfChoice";

function saveWalletOfChoice(walletName: TWallet) {
  localStorage.setItem(WalletKey, walletName);
}
type DisplayEncoding = "utf8" | "hex";
type PhantomEvent = "disconnect" | "connect";
type PhantomRequestMethod =
  | "connect"
  | "disconnect"
  | "signTransaction"
  | "signAllTransactions"
  | "signMessage";
interface ConnectOpts {
  onlyIfTrusted: boolean;
}
interface PhantomProvider {
  publicKey: PublicKey | null;
  isConnected: boolean | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signAllTransactions: (transactions: Transaction[]) => Promise<Transaction[]>;
  signMessage: (
    message: Uint8Array | string,
    display?: DisplayEncoding
  ) => Promise<any>;
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, handler: (args: any) => void) => void;
  request: (method: PhantomRequestMethod, params: any) => Promise<unknown>;
}

export async function signMessage(message: string): Promise<Uint8Array> {
  const encodedMessage = new TextEncoder().encode(message);
  const wallet = store.getState().wallet;

  if (!wallet) {
    throw new Error("valid wallet not found");
  }
  const res = await wallet.signMessage?.(encodedMessage);
  return res!;
}

function prepareUserAccount(connection: Connection, pubkey: PublicKey) {
  if (!pubkey) {
    return;
  }
  addNotification("Connected to your wallet", pubkey.toBase58());
  connection
    .getBalance(pubkey)
    .then((res) => {
      console.log("Balance", res);
      store.dispatch?.({ type: "SetBalance", balance: res });
    })
    .catch(console.error);

  getUserProgramAccounts(connection, pubkey).then((res) => {
    store.dispatch?.({ type: "SetEscrowAccounts", accounts: res.escrows });
    store.dispatch?.({ type: "SetOfferAccounts", accounts: res.offers });
  });

  connection
    .getParsedTokenAccountsByOwner(
      pubkey,
      { programId: TOKEN_PROGRAM_ID },
      "singleGossip"
    )
    .then((res) => {
      const tokens = res.value.filter((tx) => {
        if (tx.account.data.program !== "spl-token") {
          return false;
        }
        const parsed = tx.account.data.parsed;
        if (parsed.type !== "account") {
          return false;
        }
        return (
          parsed.info.tokenAmount.decimals === 0 &&
          parsed.info.tokenAmount.uiAmount === 1
        );
      });
      // const metadataAccountAddress = await findMetadataAccountAddress(mintPubKey);
      // const metadataAccount = await this.connection.getAccountInfo(
      //   metadataAccountAddress,
      //   "confirmed"
      // );
      // if (!metadataAccount) {
      //   throw new Error("metadata account could not found");
      // }

      // const metadata = decodeMetadata(metadataAccount.data);
      console.log(tokens.length);
      const mints: string[] = tokens.map(
        (t) => t.account.data.parsed.info.mint
      );
      store.dispatch?.({ type: "SetUserNFTs", nfts: mints });
    })
    .catch((err) => {
      console.error(err);
      store.dispatch?.({ type: "SetError", where: "SetUserNFTs", error: err });
    });
}

export function connectToWallet(wallet: WalletContextState) {
  const userWallet = wallet.wallet?.adapter;
  if (userWallet) {
    //save wallet to state
    store.dispatch?.({ type: "SetWallet", wallet });

    const connection = store.getState().connection;
    if (userWallet.publicKey) {
      prepareUserAccount(connection, userWallet.publicKey);
    }
    userWallet.on("disconnect", () => {
      store.dispatch?.({ type: "SetWallet", wallet: undefined });
      userWallet?.removeAllListeners();
    });
    userWallet
      .connect()
      .then(() => {
        console.log("User Wallet connect end");
      })
      .catch(console.error);
  }
  return userWallet;
}

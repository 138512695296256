import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import React from "react";
import { EscrowAndPubKey, OfferAndPubKey } from "../api/app/instructions";
import { getUserProgramAccounts } from "../api/app/utils";
import { useSelector } from "../api/store";

export function useAccounts(pubkey?: string): {
  ready: boolean;
  userNFTS: string[];
  escrowAccounts: EscrowAndPubKey[];
  offerAccounts: OfferAndPubKey[];
} {
  const connection = useSelector((data) => data.connection);
  const [escrowAccounts, setEscrowAccounts] = React.useState<EscrowAndPubKey[]>();
  const [offerAccounts, setOfferAccounts] = React.useState<OfferAndPubKey[]>();
  const [userNFTS, setUserNFTS] = React.useState<string[]>();

  React.useEffect(() => {
    setEscrowAccounts(undefined);
    setOfferAccounts(undefined);
    setUserNFTS(undefined);

    if (!pubkey) {
      return
    }

    const pk = new PublicKey(pubkey);
    getUserProgramAccounts(connection, pk).then((res) => {
      setEscrowAccounts(res.escrows);
      setOfferAccounts(res.offers);
    });

    connection
      .getParsedTokenAccountsByOwner(
        pk,
        { programId: TOKEN_PROGRAM_ID },
        "singleGossip"
      )
      .then((res) => {
        const tokens = res.value.filter((tx) => {
          if (tx.account.data.program !== "spl-token") {
            return false;
          }
          const parsed = tx.account.data.parsed;
          if (parsed.type !== "account") {
            return false;
          }
          return (
            parsed.info.tokenAmount.decimals === 0 &&
            parsed.info.tokenAmount.uiAmount === 1
          );
        });
        const mints: string[] = tokens.map(
          (t) => t.account.data.parsed.info.mint
        );
        setUserNFTS(mints);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [pubkey]);

  return {
    ready: !!userNFTS && !!escrowAccounts && !!offerAccounts,
    userNFTS: userNFTS || [],
    escrowAccounts: escrowAccounts || [],
    offerAccounts: offerAccounts || [],
  };
}

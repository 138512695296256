import { OrderBy, ListCollectionReq } from "../data/marketplace.pb";
import { store } from "./store";
import { Filter } from "./types";
import { API_ADDRESS } from "./app/constants";
import { filterCollection } from "./api";
import { addNotification } from "../utils/alert";
import {
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
} from "@solana/web3.js";
import { App, AppAPIs, Options } from "./app/client";
import {
  confirmTransaction,
  findNFTOfferAccountAddress,
  getMintOfferAccounts,
} from "./app/utils";
import { EscrowData } from "./app/instructions";
import {useWallet, WalletContextState} from "@solana/wallet-adapter-react";
import { TokenInfo } from "../data/custom";

export function addFilter(filter: Filter) {
  store.dispatch?.({
    type: "AddFilter",
    filter: filter,
  });
}

export function setFiltersAndOrder(filters: Filter[], orderBy?: OrderBy) {
  store.dispatch?.({
    type: "SetFiltersAndOrder",
    filters: filters,
    orderBy: orderBy,
  });
}

export function removeFilter(filter: Filter) {
  store.dispatch?.({
    type: "RemoveFilter",
    filter: filter,
  });
}

export function changeSort(orderBy: OrderBy) {
  store.dispatch?.({
    type: "ChangeOrder",
    orderBy,
  });
}

export function changeExploreOrder(exploreOrderBy: string) {
  store.dispatch?.({
    type: "ChangeExploreOrder",
    exploreOrderBy,
  });
}

export function fetchCollectionFloor(collectionID: string) {
  const req: ListCollectionReq = {
    collectionId: collectionID,
  };
  filterCollection(req).then((res) => {
    store.dispatch?.({
      type: "AddFloorPrice",
      collection: collectionID,
      price: res.floorPrice,
    });
  });
}

export function listCollection(collectionID: string) {
  const { filters, orderBy } = store.getState();
  const req: ListCollectionReq = {
    collectionId: collectionID,
    orderBy: orderBy,
    status: [],
    traits: [],
  };
  const traits: { [key: string]: string[] } = {};
  for (let f of filters) {
    if (f.type === "status") {
      req.status!.push(f.status);
    } else {
      if (f.key in traits) {
        traits[f.key].push(f.value);
      } else {
        traits[f.key] = [f.value];
      }
    }
  }
  for (const k of Object.keys(traits)) {
    req.traits!.push({ key: k, values: traits[k] });
  }
  store.dispatch?.({
    type: "ResetTokenList",
  });
  filterCollection(req)
    .then((res) => {
      store.dispatch?.({
        type: "AppendTokenList",
        tokenList: res.tokens ?? [],
        nextPageToken: res.nextPage,
        expectedTokenCount: res.total,
      });
      store.dispatch?.({
        type: "AddFloorPrice",
        collection: collectionID,
        price: res.floorPrice,
      });
      if (
        typeof res.floorPrice === "undefined" &&
        typeof res.tokens === "undefined" &&
        req.status?.includes("BUY_NOW") &&
        req.traits?.length === 0
      ) {
        store.dispatch?.({
          type: "RemoveFilter",
          filter: { type: "status", status: "BUY_NOW" },
        });
      } else {
        store.dispatch?.({ type: "FilterResetProgress", value: false });
      }
    })
    .catch((err) => {
      addNotification("Unable to filter tokens", `${err}`, "error");
      console.error(err);
    });
}

const reqsOnTheFly: { [key: string]: boolean } = {};

export function fetchNextPage() {
  const nextPage = store.getState().nextPageToken;
  if (!nextPage) {
    return;
  }
  const ot = reqsOnTheFly[nextPage];
  if (typeof ot !== "undefined") {
    return;
  }
  reqsOnTheFly[nextPage] = true;
  filterCollection({ token: nextPage })
    .then((res) => {
      store.dispatch?.({
        type: "AppendTokenList",
        tokenList: res.tokens ?? [],
        nextPageToken: res.nextPage,
      });
    })
    .catch((err) => {
      console.error(err);
      addNotification("Unable to fetch next nft page", `${err}`, "error");
    });
}

function makeApi(
  connection: Connection,
  wallet: WalletContextState,
  api: AppAPIs
) {
  const app = new App(connection);
  const header = app.makeHeader(wallet.publicKey!, api);
  app.transactionConnection = new Connection(`${API_ADDRESS}/proxy`, {
    commitment: "confirmed",
    confirmTransactionInitialTimeout: 60000,
    disableRetryOnRateLimit: false,
    httpHeaders: {
      rpc: header,
    },
  });
  return app;
}

export async function listNFT(mintPubkey: PublicKey, priceSOL: number, wallet: WalletContextState) {
  const { connection } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const escrowKey = Keypair.generate();
  const api = makeApi(connection, wallet, {
    type: "list",
    mint: mintPubkey.toBase58(),
    escrow: escrowKey.publicKey.toBase58(),
  });
  while (true) {
    try {
      const res = await api.list(
        wallet.publicKey!,
        mintPubkey,
        priceSOL * LAMPORTS_PER_SOL,
        {
          walletSigner: wallet,
        },
        escrowKey
      );
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function updatePriceOfNFT(
  mintPubkey: PublicKey,
  listingID: PublicKey,
  priceSOL: number
) {
  const { connection, wallet } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const api = makeApi(connection, wallet, {
    type: "updateListing",
    escrow: listingID.toBase58(),
  });
  while (true) {
    try {
      const res = await api.updateListing(
        wallet.publicKey!,
        listingID,
        mintPubkey,
        priceSOL * LAMPORTS_PER_SOL,
        {
          walletSigner: wallet,
        }
      );
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function unlistNFT(mintPubkey: PublicKey, listingID: PublicKey, wallet: WalletContextState) {
  const { connection } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const api = makeApi(connection, wallet, {
    type: "unlist",
    escrow: listingID.toBase58(),
  });
  while (true) {
    try {
      const res = await api.unlist(wallet.publicKey!, mintPubkey, listingID, {
        walletSigner: wallet,
      });
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function purchaseNFT(
  mintPubkey: PublicKey,
  escrow: EscrowData,
  listingID: PublicKey,
  wallet: WalletContextState
) {
  const { connection } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const api = makeApi(connection, wallet, {
    type: "exchange",
    mint: mintPubkey.toBase58(),
    escrow: listingID.toBase58(),
  });
  const opts: Options = {
    walletSigner: wallet,
  };
  while (true) {
    try {
      const res = await api.exchange(
        wallet.publicKey!,
        escrow,
        mintPubkey,
        listingID,
        opts
      );
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function sendOffer(
  mintPubkey: PublicKey,
  expiresAt: Date,
  priceSOL: number,
  wallet: WalletContextState
) {
  const { connection } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const offerKeypair = Keypair.generate();
  const api = makeApi(connection, wallet, {
    type: "offer",
    mint: mintPubkey.toBase58(),
    offer: offerKeypair.publicKey.toBase58(),
  });
  while (true) {
    try {
      const res = await api.offer(
        wallet.publicKey!,
        expiresAt,
        mintPubkey,
        priceSOL * LAMPORTS_PER_SOL,
        {
          walletSigner: wallet,
        },
        offerKeypair
      );
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function sendNFTOffer(
  mintPubkey: PublicKey,
  expiresAt: Date,
  nfts: PublicKey[]
) {
  const { connection, wallet } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const seed = Keypair.generate().publicKey.toBase58().substring(0, 8);
  const offerAccount = await findNFTOfferAccountAddress(
    wallet.publicKey!,
    seed
  );
  const api = makeApi(connection, wallet, {
    type: "offer",
    mint: mintPubkey.toBase58(),
    offer: offerAccount.toBase58(),
  });
  while (true) {
    try {
      const res = await api.nftOffer(
        wallet.publicKey!,
        expiresAt,
        mintPubkey,
        nfts,
        {
          walletSigner: wallet,
          seed: seed,
        }
      );
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function cancelOffer(offerKey: PublicKey, wallet: WalletContextState) {
  const { connection } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const api = makeApi(connection, wallet, {
    type: "cancelOffer",
    offer: offerKey.toBase58(),
  });
  while (true) {
    try {
      const res = await api.cancelOffer(wallet.publicKey!, offerKey, {
        walletSigner: wallet,
      });
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function closeOffer(offerKey: PublicKey) {
  const { connection, wallet } = store.getState();
  if (!wallet) {
    throw new Error("Wallet is invalid");
  }
  const api = makeApi(connection, wallet, {
    type: "closeOffer",
    offer: offerKey.toBase58(),
  });
  while (true) {
    try {
      const res = await api.closeOffer(wallet.publicKey!, offerKey, {
        walletSigner: wallet,
      });
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function acceptOffer(offerKey: PublicKey, mint: PublicKey, wallet: WalletContextState) {
  const { connection } = store.getState();
  if (!wallet || !wallet.publicKey) {
    throw new Error("Wallet is invalid");
  }
  const api = makeApi(connection, wallet, {
    type: "acceptOffer",
    offer: offerKey.toBase58(),
    mint: mint.toBase58(),
  });
  const opts: Options = {
    walletSigner: wallet,
  };
  while (true) {
    try {
      const res = await api.acceptOffer(wallet.publicKey, offerKey, mint, opts);
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function fetchOffers(publicKey: string) {
  const { connection } = store.getState();
  const offers = await getMintOfferAccounts(
    connection,
    publicKey,
    "singleGossip"
  );
  store.dispatch?.({ type: "SetNFTOffers", offers, publicKey });
}

export async function stakePiggies(piggies: PublicKey[], wallet: WalletContextState, slots?: number) {
  const { connection } = store.getState();
  if (!wallet || !wallet.publicKey) {
    throw new Error("Wallet is invalid");
  }
  const api = new App(connection);
  const opts: Options = {
    walletSigner: wallet,
    stakeSlots: slots,
  };
  while (true) {
    try {
      const res = await api.stakeMulti(wallet.publicKey, piggies, opts);
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export async function unstakePiggy(piggy: PublicKey, wallet: WalletContextState) {
  const { connection  } = store.getState();
  if (!wallet || !wallet.publicKey) {
    throw new Error("Wallet is invalid");
  }
  const api = new App(connection);
  const opts: Options = {
    walletSigner: wallet,
  };
  while (true) {
    try {
      const res = await api.unstake(wallet.publicKey, piggy, opts);
      console.log(res);
      const isConfirmed = await confirmTransaction(
        connection,
        res.signature,
        res.lastValidBlockHeight
      );
      if (isConfirmed) {
        return res;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}

export function addOpenedSection(section: string) {
  store.dispatch?.({
    type: "AddOpenedSection",
    section: section,
  });
}

export function removeOpenedSection(section: string) {
  store.dispatch?.({
    type: "RemoveOpenedSection",
    section: section,
  });
}

export function SetStateDarkMode(darkMode: boolean) {
  store.dispatch?.({
    type: "SetDarkMode",
    darkMode: darkMode,
  });
}

export function SetNFT(nft: TokenInfo) {
  store.dispatch?.({
    type: "SetNFT",
    nft: nft,
  });
}

export function ChangeExploreSortation(exploreOrderBy: string) {
  store.dispatch?.({
    type: "ChangeExploreOrder",
    exploreOrderBy: exploreOrderBy,
  });
}
import { ComponentProps, FC, ReactNode } from 'react';
import classNames from 'classnames';
import ButtonGroup from './ButtonGroup';

export type Color = 'blue' | 'alternative' | 'dark' | 'light' | 'green' | 'red' | 'yellow' | 'purple';
type GradientMonochrome = 'blue' | 'green' | 'cyan' | 'teal' | 'lime' | 'red' | 'pink' | 'purple';
type GradientDuoTone =
  | 'purpleToBlue'
  | 'cyanToBlue'
  | 'greenToBlue'
  | 'purpleToPink'
  | 'pinkToOrange'
  | 'tealToLime'
  | 'redToYellow';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type PositionInGroup = 'start' | 'middle' | 'end';

export type ButtonComponentProps = Omit<ComponentProps<'button'>, 'color'> & {
  pill?: boolean;
  outline?: boolean;
  label?: ReactNode;
  color?: Color;
  size?: Size;
  icon?: FC<ComponentProps<'svg'>>;
  gradientMonochrome?: GradientMonochrome;
  gradientDuoTone?: GradientDuoTone;
  positionInGroup?: PositionInGroup;
};

const colorClasses: Record<Color, string> = {
  blue: 'text-white bg-blue-700 border border-transparent hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600',
  alternative:
    'text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 disabled:hover:bg-white focus:ring-blue-700 focus:text-blue-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-2 dark:disabled:hover:bg-gray-800',
  dark: 'text-white bg-gray-600 border border-transparent hover:bg-gray-700 focus:ring-4 focus:ring-gray-100 disabled:hover:bg-gray-500 dark:bg-zinc-700 dark:hover:bg-zinc-600 dark:focus:ring-zinc-800 dark:border-zinc-600 dark:disabled:hover:bg-zinc-600',
  light:
    'text-gray-900 bg-white border border-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-100 disabled:hover:bg-white dark:bg-zinc-500 dark:text-gray-300 dark:hover:text-white dark:border-zinc-600 dark:hover:bg-zinc-600 dark:hover:border-zinc-700 dark:focus:ring-zinc-700',
  green:
    'text-white bg-green-700 border border-transparent hover:bg-green-800 focus:ring-4 focus:ring-green-300 disabled:hover:bg-green-700 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 dark:disabled:hover:bg-green-600',
  red: 'text-white bg-red-700 border border-transparent hover:bg-red-800 focus:ring-4 focus:ring-red-300 disabled:hover:bg-red-800 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 dark:disabled:hover:bg-red-600',
  yellow:
    'text-white bg-yellow-400 border border-transparent hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 disabled:hover:bg-yellow-400 dark:focus:ring-yellow-900 dark:disabled:hover:bg-yellow-400',
  purple:
    'text-white bg-purple-700 border border-transparent hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 disabled:hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 dark:disabled:hover:bg-purple-600',
};

const gradientMonochromeClasses: Record<GradientMonochrome, string> = {
  blue: 'text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 ',
  green:
    'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800',
  cyan: 'text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
  teal: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-800',
  lime: 'text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800',
  red: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800',
  pink: 'text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-pink-300 dark:focus:ring-pink-800',
  purple:
    'text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-800',
};

const gradientDuoToneClasses: Record<GradientDuoTone, string> = {
  purpleToBlue:
    'text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800',
  cyanToBlue:
    'text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:ring-cyan-300 dark:focus:ring-cyan-800',
  greenToBlue:
    'text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800',
  purpleToPink:
    'text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800',
  pinkToOrange:
    'text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800',
  tealToLime:
    'text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 hover:!text-gray-900 focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700',
  redToYellow:
    'text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400',
};

const sizeClasses: Record<Size, string> = {
  xs: 'text-xs px-2 py-1',
  sm: 'text-sm px-3 py-1.5',
  md: 'text-sm px-4 py-2',
  lg: 'text-base px-5 py-2.5',
  xl: 'text-base px-6 py-3',
};

const iconSizeClasses: Record<Size, string> = {
  xs: '!px-1',
  sm: '!px-1.5',
  md: '!px-2',
  lg: '!p-2.5',
  xl: '!p-3',
};

const ButtonComponent: FC<ButtonComponentProps> = ({
  children,
  className,
  label,
  pill,
  outline,
  disabled = false,
  size = 'md',
  icon: Icon,
  color = 'blue',
  gradientMonochrome,
  gradientDuoTone,
  positionInGroup,
  ...props
}) => (
  <button
    data-testid="button-element"
    disabled={disabled}
    className={classNames(
      'group flex h-min w-fit items-center justify-center p-0.5 text-center font-medium focus:z-10',
      pill ? 'rounded-full' : 'rounded-lg',
      !gradientMonochrome && !gradientDuoTone && colorClasses[color],
      !gradientDuoTone && gradientMonochrome && gradientMonochromeClasses[gradientMonochrome],
      gradientDuoTone && gradientDuoToneClasses[gradientDuoTone],
      {
        'border border-gray-900 dark:border-white': color === 'alternative' && outline,
        'cursor-not-allowed opacity-50': disabled,
        'focus:!ring-2': !!positionInGroup,
        'rounded-r-none': positionInGroup === 'start',
        '!rounded-none border-l-0 pl-0': positionInGroup === 'middle',
        'rounded-l-none border-l-0 pl-0': positionInGroup === 'end',
      },
      className,
    )}
    type="button"
    {...props}
  >
    <span
      className={classNames('flex items-center', sizeClasses[size], outline && pill ? 'rounded-full' : 'rounded-md', {
        'bg-white text-gray-900 transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit dark:bg-gray-900 dark:text-white':
          outline,
        'rounded-r-none': positionInGroup === 'start',
        '!rounded-none': positionInGroup === 'middle',
        'rounded-l-none': positionInGroup === 'end',
        [iconSizeClasses[size]]: !!Icon,
      })}
    >
      {Icon ? (
        <Icon className="h-5 w-5" />
      ) : (
        <>
          {children}
          {label && (
            <span className="ml-2 inline-flex h-4 w-4 items-center justify-center rounded-full bg-blue-200 text-xs font-semibold text-blue-800">
              {label}
            </span>
          )}
        </>
      )}
    </span>
  </button>
);

ButtonComponent.displayName = 'Button';
export const Button = Object.assign(ButtonComponent, {
  Group: ButtonGroup,
});

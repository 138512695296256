import { PublicKey } from "@solana/web3.js";
import { API_ADDRESS, TRADING_TYPES_TO_ID } from "./app/constants";
import * as marketplacepb from "../data/marketplace.pb";
import * as formpb from "../data/form.pb";
import { TokenInfo } from "../data/custom";
import { store } from "./store";
import { getMintEscrowAccount } from "./app/utils";
import { EscrowAndPubKey } from "./app/instructions";
import { signMessage } from "./wallet";
import { TradingHistoryItem } from "../data/marketplace.pb";
import {
  BaseCollectionData, CategoryData,
  NewCollectionData,
  TrendingCollectionData,
  UpcomingCollectionData
} from "../data/collection";

export async function getCollectionMeta(idOrSlug: string) {
  const res = await fetch(`${API_ADDRESS}/api/v1/collection/${idOrSlug}`);
  const data: marketplacepb.CollectionMeta = await res.json();
  store.dispatch?.({ type: "AddCollectionMeta", data: data });
  return data;
}

const collectionMetaStatus = new Map<string, boolean>();
export async function getCollectionMetaThrottled(idOrSlug: string) {
  if (
    collectionMetaStatus.has(idOrSlug) &&
    collectionMetaStatus.get(idOrSlug)
  ) {
    return;
  }
  collectionMetaStatus.set(idOrSlug, true);
  try {
    const res = await fetch(`${API_ADDRESS}/api/v1/collection/${idOrSlug}`);
    const data: marketplacepb.CollectionMeta = await res.json();
    store.dispatch?.({ type: "AddCollectionMeta", data: data });
    collectionMetaStatus.set(idOrSlug, false);
  } catch (err) {
    collectionMetaStatus.delete(idOrSlug);
  }
}

export async function tokenOffers(pubkey: string) {
  const res = await fetch(`${API_ADDRESS}/api/v1/token/${pubkey}/offers`);
  const data: marketplacepb.OfferList = await res.json();
  return data.offers ?? [];
}

export async function tokenHistory(pubkey: string) {
  const res = await fetch(`${API_ADDRESS}/api/v1/token/${pubkey}/history`);
  const data: marketplacepb.TradingList = await res.json();
  return data.history ?? [];
}

export async function tokenHistoryRefresh(pubkey: string) {
  const res = await fetch(`${API_ADDRESS}/api/v1/token/${pubkey}/refresh`);
  const data: marketplacepb.TradingList = await res.json();
  return data.history ?? [];
}

export async function getToken(pubkey: string): Promise<TokenInfo> {
  const res = await fetch(`${API_ADDRESS}/api/v2/token/${pubkey}`);
  const data: TokenInfo = await res.json();
  return data ?? null
}

export async function getLastListedToken(): Promise<TokenInfo> {
  const res = await fetch(`${API_ADDRESS}/api/v2/token/last-listed`);
  const data: TokenInfo = await res.json();
  return data ?? null
}

export async function getTokenAndEscrow(
  pubkey: string,
  shortcut?: (token: TokenInfo) => void
): Promise<[TokenInfo, EscrowAndPubKey | undefined]> {
  //TODO: get token account as well to find current real owner
  const res = await fetch(`${API_ADDRESS}/api/v1/token/${pubkey}`);
  const data: TokenInfo = await res.json();
  shortcut?.(data);
  const conn = store.getState().connection;
  const tokenPK = new PublicKey(pubkey);
  const edata = await getMintEscrowAccount(conn, tokenPK, "singleGossip");
  let called = false;
  if (typeof edata === "undefined" && typeof data.listing !== "undefined") {
    data.listing = undefined;
    called = true;
    tokenHistoryRefresh(pubkey)
      .then((res) => {
        /* noop */
      })
      .catch((err) => {
        /* noop */
      });
  }
  // if (!edata) {
  //   try {
  //     const la = await conn.getTokenLargestAccounts(tokenPK, "singleGossip");
  //     const account = la.value.filter(
  //       (x) =>
  //         x.decimals === 0 && (x.uiAmountString === "1" || x.uiAmount === 1.0)
  //     )[0];
  //     const associatedAddress = await Token.getAssociatedTokenAddress(
  //       ASSOCIATED_TOKEN_PROGRAM_ID,
  //       TOKEN_PROGRAM_ID,
  //       tokenPK,
  //       new PublicKey(data.token?.mintPubkey!)
  //     );
  //     if (
  //       associatedAddress.toBase58() !== account.address.toBase58() &&
  //       !called
  //     ) {
  //       tokenHistoryRefresh(pubkey)
  //         .then((res) => {
  //           /* noop */
  //         })
  //         .catch((err) => {
  //           /* noop */
  //         });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  return [data, edata];
}

export async function filterCollection(req: marketplacepb.ListCollectionReq) {
  const res = await fetch(`${API_ADDRESS}/api/v1/collection`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  const data: marketplacepb.ListCollectionRes = await res.json();
  return data;
}

export async function tradingActivity(req: marketplacepb.FetchTradingHistoryReq) {
  let url = new URL(`${API_ADDRESS}/api/v2/activity`)
  if (req.resourceType === "COLLECTION") {
    url = new URL(`${API_ADDRESS}/api/v2/activity/collection/${req.id}`)
  } else if (req.resourceType === "TOKEN") {
    url = new URL(`${API_ADDRESS}/api/v2/activity/token/${req.id}`)
  } else if (req.resourceType === "USER") {
    url = new URL(`${API_ADDRESS}/api/v2/activity/user/${req.id}`)
  }
  const params = new URLSearchParams()
  if (req.tradingTypes) {
    params.append('trading_types', req.tradingTypes.map(tp => TRADING_TYPES_TO_ID[tp]).join(','))
  }
  if (req.before) {
    params.append('before', req.before)
  }
  if (req.noForeignListing) {
    params.append('no_foreign_listing', req.noForeignListing.toString())
  }
  url.search = params.toString();
  const res = await fetch(url.toString(), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  });
  const data: marketplacepb.TradingHistoryItem[] = await res.json();
  return data;
}

export async function getTokenList(
  owner: string | undefined,
  tokens: string[],
  nodispatch?: boolean
) {
  const res = await fetch(`${API_ADDRESS}/api/v1/token/list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ owner, tokens }),
  });
  const data: marketplacepb.TokensAndCollections = await res.json();
  if (nodispatch !== true) {
    store.dispatch?.({ type: "SetUserTokens", tokens: data.tokens ?? [] });
  }
  return data;
}

export async function controlPrice(escrow: PublicKey) {
  const res = await fetch(`${API_ADDRESS}/api/v1/token/controlPrice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pubkey: escrow.toBase58(),
    }),
  });
  const data: marketplacepb.Listing = await res.json();
  return data;
}

// User API
export async function offersOfUser(pubkey: string) {
  const res = await fetch(`${API_ADDRESS}/api/v1/user/${pubkey}/offers`);
  const data: marketplacepb.OfferList = await res.json();
  return data.offers ?? [];
}

const userReqOnTheFlight = new Map<string, boolean>();

export async function fetchUser(pubkey: string) {
  if (typeof pubkey !== "string") {
    return;
  }
  if (userReqOnTheFlight.has(pubkey)) {
    return;
  }
  userReqOnTheFlight.set(pubkey, true);
  const res = await fetch(`${API_ADDRESS}/api/v1/user/${pubkey}`);
  if (res.ok) {
    const data: marketplacepb.User = await res.json();
    store.dispatch?.({ type: "AddUser", user: data });
  } else {
    userReqOnTheFlight.set(pubkey, false);
    throw await res.json();
  }
}
export async function getUser(
  pubkey: string,
  checkAdmin?: boolean
): Promise<marketplacepb.User> {
  userReqOnTheFlight.set(pubkey, true);
  let uri = `${API_ADDRESS}/api/v1/user/${pubkey}`;
  if (checkAdmin === true) {
    uri += "?isAdmin=1";
  }
  const res = await fetch(uri);
  if (res.ok) {
    const data: marketplacepb.User = await res.json();
    store.dispatch?.({ type: "AddUser", user: data });
    return data;
  } else {
    userReqOnTheFlight.set(pubkey, false);
    throw await res.json();
  }
}

function encode(data: string) {
  return window
    .btoa(data)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

async function signUserReq(
  pubkey: string,
  user: Partial<marketplacepb.User> & {
    exp?: number;
    iat?: number;
    admin?: boolean;
  },
  cache?: boolean
) {
  const dd: any = {};
  if (user.email) {
    dd["email"] = user.email;
  }
  if (user.username) {
    dd["username"] = user.username;
  }
  if (user.exp) {
    dd["exp"] = user.exp;
  }
  if (user.iat) {
    dd["iat"] = user.iat;
  }
  if (user.admin) {
    dd["admin"] = user.admin;
  }
  if (user.annotations) {
    dd["annotations"] = user.annotations;
  }
  if (typeof user.minimumOffer === "number") {
    dd["minimumOffer"] = user.minimumOffer;
  }
  if (user.minimumCollectionOffers) {
    dd["minimumCollectionOffers"] = user.minimumCollectionOffers;
  }
  if (user.notifications) {
    dd["notifications"] = user.notifications;
  }
  const header = { alg: "ed25519", kid: pubkey };
  const p1 = JSON.stringify(header);
  const p2 = JSON.stringify(dd);
  const p12 = encode(p1) + "." + encode(p2);
  const signed = await signMessage(p12);
  const buf = Buffer.from(signed);
  const b2 = buf
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  const at = p12 + "." + b2;
  if (cache && user.exp) {
    store.dispatch?.({
      type: "SetAccessToken",
      expAt: user.exp ?? 0,
      of: pubkey,
      token: at,
      admin: user.admin,
    });
  }
  return { jws: at };
}

export async function getUserFullData(pubkey: string) {
  const iat = Math.round(Date.now() / 1000);
  const exp = iat + 60 * 60; // 1 hour
  const req = await signUserReq(pubkey, { exp, iat });
  const res = await fetch(`${API_ADDRESS}/api/v1/user/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + req.jws,
    },
  });
  if (res.ok) {
    const data: marketplacepb.User = await res.json();
    return data;
  } else {
    throw await res.json();
  }
}

export async function updateUser(
  pubkey: string,
  user: Partial<
    Pick<
      marketplacepb.User,
      | "email"
      | "username"
      | "annotations"
      | "minimumOffer"
      | "minimumCollectionOffers"
      | "notifications"
    >
  >
) {
  const req = await signUserReq(pubkey, user);
  const res = await fetch(`${API_ADDRESS}/api/v1/user/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });

  if (res.ok) {
    const data: marketplacepb.User = await res.json();
    store.dispatch?.({ type: "AddUser", user: data });
    return data;
  } else {
    throw await res.json();
  }
}

export async function registerUser(
  pubkey: string,
  user: { email?: string; username?: string }
) {
  const req = await signUserReq(pubkey, user);
  const res = await fetch(`${API_ADDRESS}/api/v1/user/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  if (res.ok) {
    const data: marketplacepb.User = await res.json();
    return data;
  } else {
    throw await res.json();
  }
}

export interface Entity {
  id: string;
  title: string;
  image: string;
}
export interface SearchRes {
  entities?: Entity[];
}

export async function searchCollection(q: string) {
  const search = new URLSearchParams();
  search.set("q", q);
  search.set("t", "c");
  const res = await fetch(`${API_ADDRESS}/api/v1/search?${search.toString()}`);
  const data: SearchRes = await res.json();
  return data.entities ?? [];
}

export async function searchToken(q: string, collection: string) {
  const search = new URLSearchParams();
  search.set("q", q);
  search.set("t", "nft");
  search.set("i", collection);
  const res = await fetch(`${API_ADDRESS}/api/v1/search?${search.toString()}`);
  const data: SearchRes = await res.json();
  return data.entities ?? [];
}

/*
export async function listCollections(
  by: "volume" | "recent",
  limit: number = 0,
  strip: boolean = true
) {
  const search = new URLSearchParams();
  search.set("order", by);
  if (strip) {
    search.set("strip", "1");
  }
  if (limit > 0) {
    search.set("n", limit.toString());
  }
  const res = await fetch(
    `${API_ADDRESS}/api/v1/collections?${search.toString()}`
  );
  const data: {
    collections?: marketplacepb.Collection[];
  } = await res.json();
  return data.collections ?? [];
}
*/

export async function listCollections(params?: {
  limit?: number,
  offset?: number,
  categories?: string[],
  order?: string,
  dir?: string,
  search?: string
}) {
  const search = new URLSearchParams();
  if (params?.categories?.length && params?.categories?.length > 0) {
    search.set("categories", params?.categories.join(','));
  }
  if (params?.limit) {
    search.set("limit", params.limit.toString());
  }
  if (params?.offset) {
    search.set("offset", params.offset.toString());
  }
  if (params?.order) {
    search.set("order", params.order);
  }
  if (params?.dir) {
    search.set("dir", params.dir);
  }
  if (params?.search) {
    search.set("search", params.search);
  }
  const res = await fetch(`${API_ADDRESS}/api/v2/collection/list/collections?${search.toString()}`);
  const data: BaseCollectionData[] = await res.json();
  return data ?? [];
}

export async function listTrendingCollections(params?: {
  limit?: number,
  interval?: string
}) {
  const search = new URLSearchParams();
  if (params?.interval) {
    search.set("interval", params.interval);
  }
  if (params?.limit) {
    search.set("limit", params.limit.toString());
  }
  const res = await fetch(`${API_ADDRESS}/api/v2/collection/carousel/trending-collections?${search.toString()}`);
  const data: TrendingCollectionData[] = await res.json();
  return data ?? [];
}

export async function listUpcomingCollections(params?: { limit?: number }) {
  const search = new URLSearchParams();
  if (params?.limit) {
    search.set("limit", params.limit.toString());
  }
  const res = await fetch(`${API_ADDRESS}/api/v2/collection/carousel/upcoming-collections?${search.toString()}`);
  const data: UpcomingCollectionData[] = await res.json();
  return data ?? [];
}

export async function listNewCollections(params?: { limit?: number }) {
  const search = new URLSearchParams();
  if (params?.limit) {
    search.set("limit", params.limit.toString());
  }
  const res = await fetch(`${API_ADDRESS}/api/v2/collection/carousel/new-collections?${search.toString()}`);
  const data: NewCollectionData[] = await res.json();
  return data ?? [];
}

export async function listCategories() {
  const res = await fetch(`${API_ADDRESS}/api/v2/collection/list/categories`);
  const data: CategoryData[] = await res.json();
  return data ?? [];
}

export interface SystemInfoParams {
  version: 1 | 2;
  fee_basis: number;
  fee_percent: number;
}

export const LastKnwonSystemInfo: SystemInfoParams = {
  version: 1,
  fee_basis: 0,
  fee_percent: 0,
};

export async function systemInfo() {
  const res = await fetch(`${API_ADDRESS}/api/v1/system`);
  if (res.ok) {
    const data: SystemInfoParams = await res.json();
    return data;
  } else {
    throw new Error("not ok response");
  }
}

export async function signToken(pubkey: string): Promise<string> {
  const iat = Math.round(Date.now() / 1000);
  let token = "";
  const prev = store.getState().accessToken;
  if (prev && prev.expAt >= iat && prev.of === pubkey) {
    token = prev.token;
  }
  if (token === "") {
    const exp = iat + 12 * 60; // 12 minutes
    const payload = { iat, exp };
    const user = store.getState().user;
    if (user?.isAdmin === true && user?.pubkey === pubkey) {
      (payload as any)["admin"] = true;
    }
    const { jws } = await signUserReq(pubkey, payload, true);
    token = jws;
  }
  return token;
}

export async function updateCollection(
  pubkey: string,
  fields: string[],
  collection: marketplacepb.Collection
) {
  const token = await signToken(pubkey);
  const res = await fetch(`${API_ADDRESS}/api/v1/collection/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      collection,
      fields: {
        paths: fields,
      },
    }),
  });

  if (res.ok) {
    const data: marketplacepb.Collection = await res.json();
    return data;
  } else {
    throw await res.json();
  }
}

export async function sendCollectionSubmission(
  by: string,
  form: formpb.ListingApp
) {
  form.ownerPubkey = by;
  const token = await signToken(by);
  const res = await fetch(`${API_ADDRESS}/api/v1/listingapp/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify(form),
  });

  if (res.ok) {
    const data: formpb.AddListingAppRes = await res.json();
    return data.id;
  } else {
    throw await res.json();
  }
}

export async function getCollectionSubmission(by: string, id: string) {
  const token = await signToken(by);
  const res = await fetch(`${API_ADDRESS}/api/v1/listingapp/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  });

  if (res.ok) {
    const data: formpb.ListingApp = await res.json();
    return data;
  } else {
    throw await res.json();
  }
}

export async function listCollectionSubmissions(by: string) {
  //TODO: we don't need to sign every request. WE can keep this on redux and use again again
  const token = await signToken(by);
  const res = await fetch(`${API_ADDRESS}/api/v1/listingapp/list`, {
    method: "GET",
    headers: {
      authorization: token,
    },
  });

  if (res.ok) {
    const data: formpb.ListingAppList = await res.json();
    return data.listingapps ?? [];
  } else {
    throw await res.json();
  }
}
export async function filterCollectionSubmission(
  by: string,
  req: formpb.FilterListingAppReq
) {
  const token = await signToken(by);
  const res = await fetch(`${API_ADDRESS}/api/v1/listingapp/list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify(req),
  });

  if (res.ok) {
    const data: formpb.ListingAppList = await res.json();
    return data.listingapps ?? [];
  } else {
    throw await res.json();
  }
}

export async function updateListCollectionSubmissions(
  pubkey: string,
  fields: string[],
  form: formpb.ListingApp
) {
  const token = await signToken(pubkey);
  const res = await fetch(`${API_ADDRESS}/api/v1/listingapp/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify({
      listingapp: form,
      fields: {
        paths: fields,
      },
    }),
  });

  if (res.ok) {
    const data: formpb.ListingApp = await res.json();
    return data;
  } else {
    throw await res.json();
  }
}

import {
  PublicKey,
  TransactionInstruction,
  SystemProgram,
  AccountMeta,
  SYSVAR_RENT_PUBKEY,
  SYSVAR_CLOCK_PUBKEY,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  BinaryReader,
  deserializeUnchecked,
  BinaryWriter,
  serialize,
} from "borsh";
import base58 from "bs58";
import BN from "bn.js";
import { PROGRAM_ID, STAKE_NFT_HOLDER, STAKE_PROGRAM_ID } from "./constants";

export const extendBorsh = () => {
  (BinaryReader.prototype as any).readPubkey = function () {
    const reader = this as unknown as BinaryReader;
    const array = reader.readFixedArray(32);
    return new PublicKey(array);
  };

  (BinaryWriter.prototype as any).writePubkey = function (value: PublicKey) {
    const writer = this as unknown as BinaryWriter;
    writer.writeFixedArray(value.toBuffer());
  };

  (BinaryReader.prototype as any).readPubkeyAsString = function () {
    const reader = this as unknown as BinaryReader;
    const array = reader.readFixedArray(32);
    return base58.encode(array) as string;
  };

  (BinaryWriter.prototype as any).writePubkeyAsString = function (
    value: string
  ) {
    const writer = this as unknown as BinaryWriter;
    writer.writeFixedArray(base58.decode(value));
  };
  (BinaryReader.prototype as any).readBool = function () {
    const reader = this as unknown as BinaryReader;
    const array = reader.readFixedArray(1);
    return array[0] == 1;
  };

  (BinaryWriter.prototype as any).writeBool = function (value: boolean) {
    const writer = this as unknown as BinaryWriter;
    if (value) {
      writer.writeFixedArray(new Uint8Array([1]));
    } else {
      writer.writeFixedArray(new Uint8Array([0]));
    }
  };
};

extendBorsh();

export const METADATA_PREFIX = "metadata";

export class EscrowData {
  isInitialized: boolean;
  initializerPubkey: PublicKey;
  tempTokenAccountPubkey: PublicKey;
  mintId: PublicKey;
  price: number | BN;
  updatedAt: number | BN | undefined;

  constructor(args: {
    isInitialized: boolean;
    price: number | BN;
    initializerPubkey: PublicKey;
    tempTokenAccountPubkey: PublicKey;
    mintId: PublicKey;
    updatedAt?: number | BN;
  }) {
    this.isInitialized = args.isInitialized;
    this.price = args.price;
    this.initializerPubkey = args.initializerPubkey;
    this.tempTokenAccountPubkey = args.tempTokenAccountPubkey;
    this.mintId = args.mintId;
    this.updatedAt = args.updatedAt ?? 0;
  }

  toString() {
    return JSON.stringify(
      {
        isInitialized: this.isInitialized,
        initializerPubkey: this.initializerPubkey.toBase58(),
        tempTokenAccountPubkey: this.tempTokenAccountPubkey.toBase58(),
        mintId: this.mintId.toBase58(),
        price: this.price.toString(10),
        updatedAt: this.updatedAt?.toString(10),
      },
      null,
      "  "
    );
  }
}
export class LegacyEscrowData {
  isInitialized: boolean;
  initializerPubkey: PublicKey;
  tempTokenAccountPubkey: PublicKey;
  mintId: PublicKey;
  price: number | BN;
  constructor(args: {
    isInitialized: boolean;
    price: number | BN;
    initializerPubkey: PublicKey;
    tempTokenAccountPubkey: PublicKey;
    mintId: PublicKey;
    updatedAt: number | BN;
  }) {
    this.isInitialized = args.isInitialized;
    this.price = args.price;
    this.initializerPubkey = args.initializerPubkey;
    this.tempTokenAccountPubkey = args.tempTokenAccountPubkey;
    this.mintId = args.mintId;
  }

  toString() {
    return JSON.stringify(
      {
        isInitialized: this.isInitialized,
        initializerPubkey: this.initializerPubkey.toBase58(),
        tempTokenAccountPubkey: this.tempTokenAccountPubkey.toBase58(),
        mintId: this.mintId.toBase58(),
        price: this.price.toString(10),
      },
      null,
      "  "
    );
  }
}

export interface EscrowAndPubKey {
  escrow: EscrowData;
  pubkey: PublicKey;
}

export class OfferData {
  state: number;
  initializerPubkey: PublicKey;
  mintId: PublicKey;
  price: number | BN;
  expiresAt: number;
  tokens: PublicKey[];

  constructor(args: {
    state: number;
    price: number | BN;
    initializerPubkey: PublicKey;
    mintId: PublicKey;
    expiresAt: number;
    tokens?: PublicKey[];
  }) {
    this.state = args.state;
    this.price = args.price;
    this.initializerPubkey = args.initializerPubkey;
    this.expiresAt = args.expiresAt;
    this.mintId = args.mintId;
    this.tokens = args.tokens ?? [];
  }

  toString() {
    return JSON.stringify(
      {
        state: this.state,
        initializerPubkey: this.initializerPubkey.toBase58(),
        mintId: this.mintId.toBase58(),
        price: this.price.toString(10),
        expiresAt: this.expiresAt.toString(10),
        tokens: this.tokens.map((t) => t.toBase58()),
      },
      null,
      "  "
    );
  }
  get isNFTOffer(): boolean {
    return this.state === 2;
  }
}

export interface OfferAndPubKey {
  offer: OfferData;
  pubkey: PublicKey;
}
class EmptyInstructionData {
  instruction: number;
  constructor(instruction: number) {
    this.instruction = instruction;
  }
}
class PriceInstructionData {
  instruction: number;
  price: number | BN;

  constructor(instruction: number, price: number | BN) {
    this.instruction = instruction;
    this.price = price;
  }
}
class StakeInstructionData {
  instruction: number;
  slots: number;

  constructor(slots: number = 4) {
    this.instruction = 0;
    this.slots = slots;
  }
}

class OfferInstructionData {
  instruction: number;
  price: number | BN;
  expiresAt: number;

  constructor(instruction: number, price: number | BN, expiresAt: number) {
    this.instruction = instruction;
    this.price = price;
    this.expiresAt = expiresAt;
  }
}
class NFTOfferInstructionData {
  instruction: number;
  expiresAt: number;
  seed: string;

  constructor(expiresAt: number, seed: string) {
    this.instruction = 11;
    this.expiresAt = expiresAt;
    this.seed = seed;
  }
}
class OffchainOfferInstructionData {
  instruction: number;
  price: number | BN;
  mintPubkey: PublicKey;

  constructor(price: number | BN, mintPubkey: PublicKey) {
    this.instruction = 12;
    this.price = price;
    this.mintPubkey = mintPubkey;
  }
}

export class Creator {
  address: PublicKey;
  verified: boolean;
  share: number;

  constructor(args: { address: PublicKey; verified: boolean; share: number }) {
    this.address = args.address;
    this.verified = args.verified;
    this.share = args.share;
  }
}
export class Data {
  name: string;
  symbol: string;
  uri: string;
  sellerFeeBasisPoints: number;
  creators: Creator[] | null;
  constructor(args: {
    name: string;
    symbol: string;
    uri: string;
    sellerFeeBasisPoints: number;
    creators: Creator[] | null;
  }) {
    this.name = args.name;
    this.symbol = args.symbol;
    this.uri = args.uri;
    this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
    this.creators = args.creators;
  }
}

export enum MetadataKey {
  Uninitialized = 0,
  MetadataV1 = 4,
  EditionV1 = 1,
  MasterEditionV1 = 2,
  MasterEditionV2 = 6,
  EditionMarker = 7,
}

export class Metadata {
  key: MetadataKey;
  updateAuthority: PublicKey;
  mint: PublicKey;
  data: Data;
  primarySaleHappened: boolean;
  isMutable: boolean;

  constructor(args: {
    updateAuthority: PublicKey;
    mint: PublicKey;
    data: Data;
    primarySaleHappened: boolean;
    isMutable: boolean;
    editionNonce: number | null;
  }) {
    this.key = MetadataKey.MetadataV1;
    this.updateAuthority = args.updateAuthority;
    this.mint = args.mint;
    this.data = args.data;
    this.primarySaleHappened = args.primarySaleHappened;
    this.isMutable = args.isMutable;
  }
}

export class Staked {
  mint: PublicKey;
  stakedAt: number;
  constructor(args: { mint: PublicKey; stakedAt: number }) {
    this.mint = args.mint;
    this.stakedAt = args.stakedAt;
  }
  toString() {
    return JSON.stringify(
      {
        mint: this.mint.toBase58(),
        stakedAt: this.stakedAt.toString(10),
      },
      null,
      "  "
    );
  }
  toObject() {
    return {
      mint: this.mint.toBase58(),
      stakedAt: this.stakedAt.toString(10),
    };
  }
}
export class StakeUserData {
  state: number;
  initializer: PublicKey;
  tokens: Staked[];

  constructor(args: {
    state: number;
    initializer: PublicKey;
    tokens: Staked[];
  }) {
    this.state = args.state;
    this.initializer = args.initializer;
    this.tokens = args.tokens ?? [];
  }
  toString() {
    return JSON.stringify(
      {
        state: this.state,
        initializer: this.initializer.toBase58(),
        tokens: this.tokens.map((t) => t.toObject()),
      },
      null,
      "  "
    );
  }
}

const ALPHA_ART_SCHEMA = new Map<Function, any>([
  [
    Staked,
    {
      kind: "struct",
      fields: [
        ["mint", "pubkey"],
        ["stakedAt", "u64"],
      ],
    },
  ],
  [
    StakeUserData,
    {
      kind: "struct",
      fields: [
        ["state", "u8"],
        ["initializer", "pubkey"],
        ["tokens", [Staked]],
      ],
    },
  ],
  [
    PriceInstructionData,
    {
      kind: "struct",
      fields: [
        ["instruction", "u8"],
        ["price", "u64"],
      ],
    },
  ],
  [
    StakeInstructionData,
    {
      kind: "struct",
      fields: [
        ["instruction", "u8"],
        ["slots", "u64"],
      ],
    },
  ],
  [
    OfferInstructionData,
    {
      kind: "struct",
      fields: [
        ["instruction", "u8"],
        ["price", "u64"],
        ["expiresAt", "u64"],
      ],
    },
  ],
  [
    NFTOfferInstructionData,
    {
      kind: "struct",
      fields: [
        ["instruction", "u8"],
        ["expiresAt", "u64"],
        ["seed", "string"],
      ],
    },
  ],
  [
    OffchainOfferInstructionData,
    {
      kind: "struct",
      fields: [
        ["instruction", "u8"],
        ["price", "u64"],
        ["mintPubkey", "pubkey"],
      ],
    },
  ],
  [
    EmptyInstructionData,
    {
      kind: "struct",
      fields: [["instruction", "u8"]],
    },
  ],
  [
    EscrowData,
    {
      kind: "struct",
      fields: [
        ["isInitialized", "bool"],
        ["initializerPubkey", "pubkey"],
        ["tempTokenAccountPubkey", "pubkey"],
        ["mintId", "pubkey"],
        ["price", "u64"],
        ["updatedAt", "u64"],
      ],
    },
  ],

  [
    LegacyEscrowData,
    {
      kind: "struct",
      fields: [
        ["isInitialized", "bool"],
        ["initializerPubkey", "pubkey"],
        ["tempTokenAccountPubkey", "pubkey"],
        ["mintId", "pubkey"],
        ["price", "u64"],
      ],
    },
  ],
  [
    OfferData,
    {
      kind: "struct",
      fields: [
        ["state", "u8"],
        ["initializerPubkey", "pubkey"],
        ["mintId", "pubkey"],
        ["price", "u64"],
        ["expiresAt", "u64"],
        ["tokens", ["pubkey"]],
      ],
    },
  ],
  [
    Data,
    {
      kind: "struct",
      fields: [
        ["name", "string"],
        ["symbol", "string"],
        ["uri", "string"],
        ["sellerFeeBasisPoints", "u16"],
        ["creators", { kind: "option", type: [Creator] }],
      ],
    },
  ],
  [
    Creator,
    {
      kind: "struct",
      fields: [
        ["address", "pubkey"],
        ["verified", "u8"],
        ["share", "u8"],
      ],
    },
  ],
  [
    Metadata,
    {
      kind: "struct",
      fields: [
        ["key", "u8"],
        ["updateAuthority", "pubkey"],
        ["mint", "pubkey"],
        ["data", Data],
        ["primarySaleHappened", "u8"], // bool
        ["isMutable", "u8"], // bool
      ],
    },
  ],
]);

export const EscrowDataSpace = 114;
export const LegacyEscrowDataSpace = 105;
export const OfferDataSpace = 85;
export const BitchDataSpace = 48;

export function decodeOffer(buffer: Buffer): OfferData {
  const data = deserializeUnchecked(
    ALPHA_ART_SCHEMA,
    OfferData,
    buffer
  ) as OfferData;
  return data;
}
export function decodeEscrow(buffer: Buffer): EscrowData {
  if (buffer.length === 105) {
    const data = deserializeUnchecked(
      ALPHA_ART_SCHEMA,
      LegacyEscrowData,
      buffer
    ) as LegacyEscrowData;
    return new EscrowData(data);
  }
  const data = deserializeUnchecked(
    ALPHA_ART_SCHEMA,
    EscrowData,
    buffer
  ) as EscrowData;
  return data;
}
export function decodeMetadata(buffer: Buffer): Metadata {
  const metadata = deserializeUnchecked(
    ALPHA_ART_SCHEMA,
    Metadata,
    buffer
  ) as Metadata;
  return metadata;
}

export function decodeStake(buffer: Buffer): StakeUserData {
  const data = deserializeUnchecked(
    ALPHA_ART_SCHEMA,
    StakeUserData,
    buffer
  ) as StakeUserData;
  return data;
}

export function listInstruction(
  price: number | BN,
  escrow: PublicKey,
  initializer: PublicKey,
  feeCollector: PublicKey,
  tempTokenAccount: PublicKey,
  mint: PublicKey
) {
  const args = new PriceInstructionData(0, price);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys = [
    {
      pubkey: initializer,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: tempTokenAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mint,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: escrow,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: feeCollector,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function exchangeInstruction(
  price: number | BN,
  taker: PublicKey,
  takersTokenToReceiveAccount: PublicKey,
  escrow: PublicKey,
  initializerAccount: PublicKey,
  stakeAccount: PublicKey,
  tempTokenAccount: PublicKey,
  pda: PublicKey,
  mintPublicKey: PublicKey,
  metadataAccount: PublicKey,
  marketFeeAccount: PublicKey,
  creators: PublicKey[]
) {
  const args = new PriceInstructionData(2, price);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: taker,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: takersTokenToReceiveAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: tempTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: initializerAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: escrow,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: metadataAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintPublicKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: pda,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: marketFeeAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      isSigner: false,
      isWritable: false,
      pubkey: SYSVAR_CLOCK_PUBKEY,
    },
  ];
  for (const c of creators) {
    keys.push({ isSigner: false, isWritable: true, pubkey: c });
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function unlistInstruction(
  owner: PublicKey,
  ownerTokenToReceiveAccount: PublicKey,
  escrow: PublicKey,
  tempTokenAccount: PublicKey,
  pda: PublicKey,
  mintPublicKey: PublicKey
) {
  const args = new EmptyInstructionData(1);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: owner,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: ownerTokenToReceiveAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: tempTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: escrow,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: pda,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintPublicKey,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function updateListingInstruction(
  price: number | BN,
  owner: PublicKey,
  escrow: PublicKey,
  mintPublicKey: PublicKey
) {
  const args = new PriceInstructionData(6, price);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: owner,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: escrow,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: mintPublicKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function offerInstruction(
  price: number | BN,
  expiresAt: number,
  initializer: PublicKey,
  mintPubKey: PublicKey,
  offerAccount: PublicKey
) {
  const args = new OfferInstructionData(3, price, expiresAt);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys = [
    {
      pubkey: initializer,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: mintPubKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: offerAccount,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function cancelOfferInstruction(
  initializer: PublicKey,
  offerAccount: PublicKey,
  nftOffer?: {
    pda: PublicKey;
    accounts: PublicKey[];
  }
) {
  const args = new EmptyInstructionData(4);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: initializer,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: offerAccount,
      isSigner: false,
      isWritable: true,
    },
  ];
  if (nftOffer) {
    keys.push({
      pubkey: nftOffer.pda,
      isSigner: false,
      isWritable: false,
    });
    keys.push({
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    });
    for (const o of nftOffer.accounts) {
      keys.push({
        pubkey: o,
        isSigner: false,
        isWritable: true,
      });
    }
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function closeOfferIfExpiredInstruction(
  initializer: PublicKey,
  offerAccount: PublicKey,
  closer: PublicKey,
  nftOffer?: {
    pda: PublicKey;
    accounts: PublicKey[];
  }
) {
  const args = new EmptyInstructionData(7);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: initializer,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: offerAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: closer,
      isSigner: true,
      isWritable: false,
    },
  ];
  if (nftOffer) {
    keys.push({
      pubkey: nftOffer.pda,
      isSigner: false,
      isWritable: false,
    });
    keys.push({
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    });
    for (const o of nftOffer.accounts) {
      keys.push({
        pubkey: o,
        isSigner: false,
        isWritable: true,
      });
    }
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function acceptOfferInstruction(
  currentOwner: PublicKey,
  currentOwnerTokenAccount: PublicKey,
  initializer: PublicKey,
  initializerTokenAccount: PublicKey,
  offerAccount: PublicKey,
  stakeAccount: PublicKey,
  mintPubKey: PublicKey,
  metadataAccount: PublicKey,
  marketFeeAccount: PublicKey,
  creators: PublicKey[]
) {
  const args = new EmptyInstructionData(5);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));

  const keys: AccountMeta[] = [
    {
      pubkey: currentOwner,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: currentOwnerTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: initializer,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: initializerTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: offerAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintPubKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: metadataAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: marketFeeAccount,
      isSigner: false,
      isWritable: true,
    },
  ];
  for (const c of creators) {
    keys.push({ isSigner: false, isWritable: true, pubkey: c });
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function stakeInstruction(
  initializer: PublicKey,
  stakeAccount: PublicKey,
  mintPubkey: PublicKey,
  metadataPubkey: PublicKey,
  mintAccountPubkey: PublicKey,
  slots: number = 4
) {
  const args = new StakeInstructionData(slots);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: initializer,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: mintPubkey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: metadataPubkey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintAccountPubkey,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: STAKE_PROGRAM_ID,
    data: data,
  });
}

export function unstakeInstruction(
  initializer: PublicKey,
  stakeAccount: PublicKey,
  mintPubkey: PublicKey,
  mintAccountPubkey: PublicKey
) {
  const args = new EmptyInstructionData(1);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys: AccountMeta[] = [
    {
      pubkey: initializer,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: mintPubkey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintAccountPubkey,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: STAKE_NFT_HOLDER,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new TransactionInstruction({
    keys,
    programId: STAKE_PROGRAM_ID,
    data: data,
  });
}

export function nftOfferInstruction(
  expiresAt: number,
  initializer: PublicKey,
  targetMintPubKey: PublicKey,
  offerAccount: PublicKey,
  seed: string,
  stakeAccount: PublicKey,
  mintAccounts: PublicKey[],
  offerTokenAccounts: PublicKey[]
) {
  const args = new NFTOfferInstructionData(expiresAt, seed);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));
  const keys = [
    {
      pubkey: initializer,
      isSigner: true,
      isWritable: false,
    },
    {
      pubkey: offerAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: targetMintPubKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: false,
    },
  ];
  for (let i = 0; i < mintAccounts.length; i++) {
    keys.push({
      pubkey: mintAccounts[i],
      isSigner: false,
      isWritable: false,
    });
    keys.push({
      pubkey: offerTokenAccounts[i],
      isSigner: false,
      isWritable: true,
    });
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function acceptOffchainOfferInstruction(
  price: number | BN,
  currentOwner: PublicKey,
  currentOwnerTokenAccount: PublicKey,
  initializer: PublicKey,
  stakeAccount: PublicKey,
  mintPubKey: PublicKey,
  metadataAccount: PublicKey,
  marketFeeAccount: PublicKey,
  creators: PublicKey[]
) {
  const args = new OffchainOfferInstructionData(price, mintPubKey);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));

  const keys: AccountMeta[] = [
    {
      pubkey: currentOwner,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: currentOwnerTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: initializer,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintPubKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: metadataAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: marketFeeAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
  ];
  for (const c of creators) {
    keys.push({ isSigner: false, isWritable: true, pubkey: c });
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

export function acceptNFTOfferInstruction(
  currentOwner: PublicKey,
  currentOwnerTokenAccount: PublicKey,
  initializer: PublicKey,
  initializerTokenAccount: PublicKey,
  offerAccount: PublicKey,
  stakeAccount: PublicKey,
  mintPubKey: PublicKey,
  metadataAccount: PublicKey,
  marketFeeAccount: PublicKey,
  PDAAccount: PublicKey,
  nftOffers: PublicKey[]
) {
  const args = new EmptyInstructionData(5);
  const data = Buffer.from(serialize(ALPHA_ART_SCHEMA, args));

  const keys: AccountMeta[] = [
    {
      pubkey: currentOwner,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: currentOwnerTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: initializer,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: initializerTokenAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: offerAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: stakeAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: mintPubKey,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: metadataAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_ID,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: PDAAccount,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_CLOCK_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: marketFeeAccount,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
  ];
  for (const c of nftOffers) {
    keys.push({ isSigner: false, isWritable: true, pubkey: c });
  }
  return new TransactionInstruction({
    keys,
    programId: PROGRAM_ID,
    data: data,
  });
}

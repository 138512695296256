import { PublicKey, Connection, Commitment, Finality } from "@solana/web3.js";
import { METADATA_PROGRAM_ID, PROGRAM_ID, STAKE_PROGRAM_ID } from "./constants";
import {
  decodeEscrow,
  decodeOffer,
  decodeStake,
  EscrowAndPubKey,
  EscrowDataSpace,
  LegacyEscrowDataSpace,
  OfferAndPubKey,
  StakeUserData,
} from "./instructions";

export async function findMetadataAccountAddress(mintpublicKey: PublicKey) {
  const res = await PublicKey.findProgramAddress(
    [
      Buffer.from("metadata"),
      METADATA_PROGRAM_ID.toBuffer(),
      mintpublicKey.toBuffer(),
    ],
    METADATA_PROGRAM_ID
  );
  return res[0];
}

export async function findPDAAddress() {
  const res = await PublicKey.findProgramAddress(
    [Buffer.from("piggy")],
    PROGRAM_ID
  );
  return res[0];
}

export async function findStakingAccountAddress(wallet: PublicKey) {
  const res = await PublicKey.findProgramAddress(
    [Buffer.from("stake"), wallet.toBuffer()],
    STAKE_PROGRAM_ID
  );
  return res[0];
}

export async function findNFTOfferAccountAddress(
  wallet: PublicKey,
  seed: string
) {
  const res = await PublicKey.findProgramAddress(
    [Buffer.from("offer"), wallet.toBuffer(), Buffer.from(seed)],
    PROGRAM_ID
  );
  return res[0];
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getFees(
  connection: Connection
): Promise<{ blockhash: string; lastValidBlockHeight: number }> {
  const res = await (connection as any)._rpcRequest(
    "getFees",
    connection._buildArgs([], "max")
  );
  const value = res.result.value;
  const lastValidBlockHeight = value.lastValidBlockHeight;
  return { blockhash: value.blockhash, lastValidBlockHeight };
}
export async function getBlockHeight(
  connection: Connection,
  commitment?: Commitment
): Promise<number> {
  const res = await (connection as any)._rpcRequest(
    "getBlockHeight",
    connection._buildArgs([], commitment ?? "processed")
  );
  return res.result;
}

async function confirmTransactionInner(
  connection: Connection,
  signature: string,
  lastValidBlockHeight: number,
  opts?: {
    commitment?: Finality;
    maxRetry?: number;
  }
) {
  const comm = opts?.commitment ?? "confirmed";
  const maxRetry = opts?.maxRetry ?? Number.MAX_SAFE_INTEGER;
  let retry = 0;
  while (retry < maxRetry) {
    try {
      const transaction = await connection.getConfirmedTransaction(
        signature,
        comm
      );
      if (transaction?.meta?.err) {
        console.error(transaction?.meta.err);
        return false;
      }
      if (
        typeof transaction?.blockTime !== "undefined" &&
        typeof transaction?.transaction.recentBlockhash !== "undefined"
      ) {
        return true;
      }
    } catch (err) {}
    const blockHeight = await getBlockHeight(connection, "processed");
    if (blockHeight > lastValidBlockHeight) {
      console.error(
        `current blockHeight=${blockHeight} is larger than lastValidBlockHeight=${lastValidBlockHeight}`
      );
      return false;
    }
    await sleep(1000);
    retry += 1;
  }
  return false;
}

export async function confirmTransaction(
  connection: Connection,
  signature: string,
  lastValidBlockHeight: number,
  opts?: {
    commitment?: Finality;
    maxRetry?: number;
  }
) {
  return confirmTransactionInner(
    connection,
    signature,
    lastValidBlockHeight,
    opts
  );
}

export async function getUserProgramAccounts(
  connection: Connection,
  ofUser: PublicKey,
  commitment: Commitment = "singleGossip"
) {
  const res = await connection.getProgramAccounts(PROGRAM_ID, {
    commitment: commitment,
    filters: [{ memcmp: { offset: 1, bytes: ofUser.toBase58() } }],
  });
  let escrows: EscrowAndPubKey[] = [];
  let offers: OfferAndPubKey[] = [];
  res.forEach((a) => {
    if (
      a.account.data.length === EscrowDataSpace ||
      a.account.data.length === LegacyEscrowDataSpace
    ) {
      const escrow = decodeEscrow(a.account.data);
      const pubkey = a.pubkey;
      escrows.push({ escrow, pubkey });
    } else {
      const offer = decodeOffer(a.account.data);
      const pubkey = a.pubkey;
      offers.push({ offer, pubkey });
    }
  });
  return { escrows, offers };
}

export async function getMintEscrowAccount(
  connection: Connection,
  mint: PublicKey,
  commitment: Commitment = "singleGossip"
) {
  const res = await connection.getProgramAccounts(PROGRAM_ID, {
    commitment: commitment,
    filters: [{ memcmp: { offset: 65, bytes: mint.toBase58() } }],
  });
  let escrows: EscrowAndPubKey[] = [];
  res.forEach((a) => {
    if (
      a.account.data.length === EscrowDataSpace ||
      a.account.data.length === LegacyEscrowDataSpace
    ) {
      const escrow = decodeEscrow(a.account.data);
      const pubkey = a.pubkey;
      escrows.push({ escrow, pubkey });
    }
  });
  return escrows[0];
}

export async function getMintOfferAccounts(
  connection: Connection,
  mint: string,
  commitment: Commitment = "singleGossip"
) {
  const res = await connection.getProgramAccounts(PROGRAM_ID, {
    commitment: commitment,
    filters: [{ memcmp: { offset: 33, bytes: mint } }],
  });
  let offers: OfferAndPubKey[] = [];
  res.forEach((a) => {
    try {
      const offer = decodeOffer(a.account.data);
      const pubkey = a.pubkey;
      offers.push({ offer, pubkey });
    } catch (err) {}
  });
  return offers;
}

export async function getStakeAccount(connection: Connection, user: PublicKey) {
  const add = await findStakingAccountAddress(user);
  const stakeAccountInfo = await connection.getAccountInfo(add, "processed");
  if (!stakeAccountInfo) {
    throw new Error("stake account could not found");
  }
  return decodeStake(stakeAccountInfo.data);
}

export async function getStakeAccountAndAddress(
  connection: Connection,
  user: PublicKey
): Promise<[PublicKey, StakeUserData] | undefined> {
  const add = await findStakingAccountAddress(user);
  const stakeAccountInfo = await connection.getAccountInfo(add, "processed");
  if (!stakeAccountInfo) {
    return undefined;
  }
  return [add, decodeStake(stakeAccountInfo.data)];
}

import React from "react";
import { Connection } from "@solana/web3.js";
import { networkUri } from "./app/constants";
import { Action, Dispatch, Filter, State } from "./types";

const initialData: Partial<State> = {
  isConnected: false,
  filterIsInProgress: false,
  accountBalance: 0,
  collectionMetasStatus: {},
  collectionMetas: {},
  filters: [],
  orderBy: undefined,
  tokenList: [],
  filterIndex: 0,
  userNFTs: [],
  userTokenList: [],
  errors: {},
  notifications: [],
  escrows: [],
  offers: [],
  nftOffers: {},
  hasUser: "unknown",
  users: {},
  floorPrices: {},
  filterOpenedSections: [],
  darkMode: localStorage.getItem("alphaDarkMode") === "true",
  nft: {},
  exploreOrderBy: undefined,
};

const GlobalContext = React.createContext<State>(initialData as any);
const GlobalContextDispatch = React.createContext<null | Dispatch>(null);

export function useSelector<T>(cb: (state: State) => T): T {
  const data = React.useContext(GlobalContext);
  return cb(data);
}

export function useDispatch() {
  const dispatch = React.useContext(GlobalContextDispatch);
  return dispatch!;
}

function reducer(prev: State, action: Action): State {
  switch (action.type) {
    case "SetWallet": {
      const connected =
        action.wallet?.connected === true ||
        (action.wallet as any)?.isConnected === true;
      return {
        ...prev,
        wallet: action.wallet,
        isConnected: connected,
        userNFTs: connected ? prev.userNFTs : [],
      };
    }
    case "SetError":
      return {
        ...prev,
        errors: { ...prev.errors, [action.where]: action.error },
      };
    case "SetOfferAccounts":
      return { ...prev, offers: action.accounts };
    case "SetEscrowAccounts":
      return { ...prev, escrows: action.accounts };
    case "SetUserNFTs":
      return { ...prev, userNFTs: action.nfts };
    case "SetUserTokens":
      return { ...prev, userTokenList: action.tokens };
    case "SetBalance":
      return { ...prev, accountBalance: action.balance };
    case "AppendTokenList": {
      return {
        ...prev,
        tokenList: [...prev.tokenList, ...action.tokenList],
        nextPageToken: action.nextPageToken,
        expectedTokenCount:
          action.expectedTokenCount ?? prev.expectedTokenCount,
      };
    }
    case "ResetTokenList": {
      return {
        ...prev,
        tokenList: [],
        expectedTokenCount: 0,
        nextPageToken: undefined,
      };
    }
    case "AddCollectionMeta":
      return {
        ...prev,
        collectionMetas: {
          ...prev.collectionMetas,
          [action.data.collection?.id!]: action.data,
          [action.data.collection?.slug!]: action.data,
        },
        collectionMetasStatus: {
          ...prev.collectionMetasStatus,
          [action.data.collection?.id!]: true,
          [action.data.collection?.slug!]: true,
        },
      };
    case "ChangeOrder":
      return {
        ...prev,
        orderBy: action.orderBy,
        filterIsInProgress: true,
        filterIndex: prev.filterIndex + 1,
        nextPageToken: undefined,
      };
    case "FilterResetProgress":
      return {
        ...prev,
        filterIsInProgress: action.value,
      };
    case "AddFilter": {
      return {
        ...prev,
        filters: uniq([...prev.filters, action.filter]),
        filterIsInProgress: true,
        filterIndex: prev.filterIndex + 1,
        nextPageToken: undefined,
      };
    }
    case "SetFiltersAndOrder": {
      return {
        ...prev,
        filters: uniq([...action.filters]),
        orderBy: action.orderBy || prev.orderBy,
        filterIsInProgress: true,
        filterIndex: prev.filterIndex + 1,
        nextPageToken: undefined,
      };
    }
    case "ForceFilter": {
      return {
        ...prev,
        filterIsInProgress: true,
        filterIndex: prev.filterIndex + 1,
        nextPageToken: undefined,
      };
    }
    case "ResetCollectionInfo": {
      return {
        ...prev,
        tokenList: [],
        expectedTokenCount: 0,
        filterIsInProgress: true,
        filterIndex: 1,
        filters: [{ type: "status", status: "BUY_NOW" }],
        nextPageToken: undefined,
      };
    }
    case "AddUser": {
      return {
        ...prev,
        users: {
          ...prev.users,
          [action.user.pubkey!]: action.user,
        },
      };
    }
    case "RemoveFilter": {
      return {
        ...prev,
        filterIsInProgress: true,
        filterIndex: prev.filterIndex + 1,
        nextPageToken: undefined,
        filters: prev.filters.filter((pp) => {
          if (pp.type === action.filter.type) {
            if (action.filter.type === "status") {
              return action.filter.status !== (pp as any).status;
            } else {
              return (
                action.filter.key !== (pp as any).key ||
                action.filter.value !== (pp as any).value
              );
            }
          }
          return true;
        }),
      };
    }
    case "AddNotification":
      return {
        ...prev,
        notifications: [...prev.notifications, action.alert],
      };
    case "RemoveNotification":
      return {
        ...prev,
        notifications: prev.notifications.filter((n) => n.id !== action.id),
      };
    case "SetNFTOffers":
      return {
        ...prev,
        nftOffers: { ...prev.nftOffers, [action.publicKey]: action.offers },
      };
    case "AddFloorPrice":
      return {
        ...prev,
        floorPrices: {
          ...prev.floorPrices,
          [action.collection]: action.price,
        },
      };
    case "SetUser":
      if (action.user) {
        return {
          ...prev,
          user: action.user,
          users: {
            ...prev.users,
            [action.user!.pubkey!]: action.user,
          },
        };
      } else {
        return {
          ...prev,
          user: action.user,
        };
      }
    case "SetHasUser":
      return {
        ...prev,
        hasUser: action.hasUser,
      };
    case "SetAccessToken":
      return {
        ...prev,
        accessToken: {
          expAt: action.expAt,
          of: action.of,
          token: action.token,
          admin: action.admin,
        },
      };
    case "AddOpenedSection": {
      return {
        ...prev,
        filterOpenedSections: uniqSections([
          ...prev.filterOpenedSections,
          action.section,
        ]),
      };
    }
    case "RemoveOpenedSection": {
      return {
        ...prev,
        filterOpenedSections: prev.filterOpenedSections.filter(
          (n) => n !== action.section
        ),
      };
    }
    case "SetDarkMode":
      return {
        ...prev,
        darkMode: action.darkMode,
      };
    case "SetNFT":
      return {
        ...prev,
        nft: action.nft,
      };
    case "ChangeExploreOrder":
      return {
        ...prev,
        exploreOrderBy: action.exploreOrderBy,
      };
  }
}

function uniq(a: Filter[]) {
  let seen = new Set();
  return a.filter((item) => {
    let k = JSON.stringify(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

function uniqSections(a: string[]) {
  let seen = new Set();
  return a.filter((item) => {
    let k = item;
    return seen.has(k) ? false : seen.add(k);
  });
}

function makeInitialState(): State {
  return {
    ...initialData,
    connection: new Connection(networkUri()!),
  } as State;
}

export const store: {
  dispatch: null | Dispatch;
  getState: () => State;
} = {
  dispatch: null,
  getState: () => {
    return {} as any;
  },
};

export function StoreProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(
    reducer,
    undefined,
    makeInitialState
  );
  store.dispatch = dispatch;
  store.getState = () => state;
  return (
    <GlobalContext.Provider value={state}>
      <GlobalContextDispatch.Provider value={dispatch}>
        {props.children}
      </GlobalContextDispatch.Provider>
    </GlobalContext.Provider>
  );
}

import {
  Token,
  AccountLayout,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  Keypair,
  SystemProgram,
  PublicKey,
  Transaction,
  Connection,
  Signer as Web3Signer,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  findMetadataAccountAddress,
  getFees,
  findPDAAddress,
  findStakingAccountAddress,
  findNFTOfferAccountAddress,
} from "./utils";
import {
  listInstruction,
  decodeMetadata,
  exchangeInstruction,
  decodeEscrow,
  unlistInstruction,
  updateListingInstruction,
  EscrowDataSpace,
  OfferDataSpace,
  offerInstruction,
  cancelOfferInstruction,
  closeOfferIfExpiredInstruction,
  acceptOfferInstruction,
  acceptNFTOfferInstruction,
  decodeOffer,
  EscrowData,
  stakeInstruction,
  unstakeInstruction,
  OfferData,
  nftOfferInstruction,
  Metadata,
} from "./instructions";
import BN from "bn.js";
import { PROGRAM_ID, MARKET_FEE_COLLECTOR } from "./constants";
import base58 from "bs58";

export interface Signer {
  signTransaction?: (transaction: Transaction) => Promise<Transaction>;
}
const FAILED_TO_FIND_ACCOUNT = "Failed to find account";
export interface Options {
  walletSigner?: Signer;
  secondSigner?: Signer;
  keySigners?: Web3Signer[];
  listingID?: PublicKey;
  stakeSlots?: number;
  seed?: string;
}
export type AppAPIs =
  | { type: "list"; escrow: string; mint: string }
  | { type: "exchange"; escrow: string; mint: string }
  | { type: "unlist"; escrow: string }
  | { type: "updateListing"; escrow: string }
  | { type: "offer"; offer: string; mint: string }
  | { type: "cancelOffer"; offer: string }
  | { type: "closeOffer"; offer: string }
  | { type: "acceptOffer"; offer: string; mint: string };

const metadataCache: Map<string, Metadata> = new Map();
export async function getMetadata(
  connection: Connection,
  mintPubKey: PublicKey
) {
  const s = mintPubKey.toBase58();
  if (metadataCache.has(s)) {
    return metadataCache.get(s)!;
  }
  const metadataAccountAddress = await findMetadataAccountAddress(mintPubKey);
  const metadataAccount = await connection.getAccountInfo(
    metadataAccountAddress,
    "singleGossip"
  );
  if (!metadataAccount) {
    throw new Error("metadata account could not found");
  }
  const meta = decodeMetadata(metadataAccount.data);
  metadataCache.set(s, meta);
  return meta;
}

export class App {
  private connection: Connection;
  public transactionConnection: Connection | undefined;

  constructor(connection: Connection) {
    this.connection = connection;
  }

  private async sendTransaction(
    feePayer: PublicKey,
    transaction: Transaction,
    options: Options,
    extraSigners?: Keypair[]
  ) {
    const { blockhash, lastValidBlockHeight } = await getFees(this.connection);
    transaction.feePayer = feePayer;
    transaction.recentBlockhash = blockhash;
    const conn = this.transactionConnection ?? this.connection;
    if (options.keySigners && (options.keySigners?.length ?? 0) > 0) {
      const signers = [...options.keySigners];
      if (extraSigners) {
        signers.push(...extraSigners);
      }
      transaction.sign(...signers);
      if (options.secondSigner) {
        await options.secondSigner.signTransaction?.(transaction);
      }
      const wireTransaction = transaction.serialize();
      const signature = await conn.sendRawTransaction(wireTransaction, {
        skipPreflight: true,
      });
      return {
        signature,
        lastValidBlockHeight,
      };
    } else if (options.walletSigner) {
      if (extraSigners && extraSigners.length > 0) {
        transaction.partialSign(...extraSigners);
      }
      if (options.secondSigner) {
        await options.secondSigner.signTransaction?.(transaction);
      }
      const signed = await options.walletSigner.signTransaction?.(transaction);
      const signature = await conn.sendRawTransaction(signed!.serialize(), {
        skipPreflight: true,
      });
      return {
        signature,
        lastValidBlockHeight,
      };
    } else {
      throw new Error("there is no signer");
    }
  }

  async getParsedTokenAccountsByOwner(
    owner: PublicKey,
    mintPublicKey: PublicKey
  ) {
    const accounts = await this.connection.getParsedTokenAccountsByOwner(
      owner,
      {
        mint: mintPublicKey,
      },
      "processed"
    );
    const mm = accounts.value.filter(
      (a) => a.account.data.parsed.info.tokenAmount.uiAmount > 0
    );
    if (mm.length !== 1) {
      throw new Error(FAILED_TO_FIND_ACCOUNT);
    }
    return mm[0].pubkey;
  }

  async getTokenLargestAccount(mintPublicKey: PublicKey) {
    const accounts = await this.connection.getTokenLargestAccounts(
      mintPublicKey,
      "processed"
    );
    const mm = accounts.value.filter((a) => (a.uiAmount ?? 0) > 0);
    if (mm.length !== 1) {
      throw new Error(FAILED_TO_FIND_ACCOUNT);
    }
    return mm[0].address;
  }

  async list(
    owner: PublicKey,
    mintPublicKey: PublicKey,
    priceLamports: number | BN,
    options: Options,
    escrowKey?: Keypair
  ) {
    const balanceNeeded = await Token.getMinBalanceRentForExemptAccount(
      this.connection
    );
    const newAccount = Keypair.generate();
    const escrowAccount = escrowKey ?? Keypair.generate();
    const transaction = new Transaction();
    const accountKey = await this.getParsedTokenAccountsByOwner(
      owner,
      mintPublicKey
    );

    transaction.add(
      SystemProgram.createAccount({
        programId: TOKEN_PROGRAM_ID,
        space: AccountLayout.span,
        lamports: balanceNeeded,
        fromPubkey: owner,
        newAccountPubkey: newAccount.publicKey,
      })
    );
    transaction.add(
      Token.createInitAccountInstruction(
        TOKEN_PROGRAM_ID,
        mintPublicKey,
        newAccount.publicKey,
        owner
      )
    );
    transaction.add(
      Token.createTransferInstruction(
        TOKEN_PROGRAM_ID,
        accountKey,
        newAccount.publicKey,
        owner,
        [],
        1
      )
    );
    const minimumAmount =
      await this.connection.getMinimumBalanceForRentExemption(EscrowDataSpace);

    transaction.add(
      SystemProgram.createAccount({
        fromPubkey: owner,
        lamports: minimumAmount,
        newAccountPubkey: escrowAccount.publicKey,
        programId: PROGRAM_ID,
        space: EscrowDataSpace,
      })
    );
    transaction.add(
      listInstruction(
        priceLamports,
        escrowAccount.publicKey,
        owner,
        MARKET_FEE_COLLECTOR,
        newAccount.publicKey,
        mintPublicKey
      )
    );
    const res = await this.sendTransaction(owner, transaction, options, [
      escrowAccount,
      newAccount,
    ]);
    return { ...res, listingID: escrowAccount.publicKey };
  }

  async exchange(
    user: PublicKey,
    escrowData: EscrowData,
    mintPublicKey: PublicKey,
    listingID: PublicKey,
    options: Options
  ) {
    const transaction = new Transaction();
    const pda = await findPDAAddress();
    const metadataAccountAddress = await findMetadataAccountAddress(
      mintPublicKey
    );
    const metadata = await getMetadata(this.connection, mintPublicKey);
    const associatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintPublicKey,
      user
    );

    let accountExists = false;
    try {
      const aa = await this.connection.getAccountInfo(
        associatedAddress,
        "confirmed"
      );
      if (aa) {
        accountExists = true;
      }
    } catch (err) {
      console.warn(err);
    }

    if (!accountExists) {
      transaction.add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintPublicKey,
          associatedAddress,
          user,
          user
        )
      );
    }
    const escrowAccount = await this.connection.getAccountInfo(
      listingID,
      "confirmed"
    );
    if (!escrowAccount) {
      throw new Error("escrow account could not found");
    }
    const escrow = decodeEscrow(escrowAccount.data);
    console.log("escrow", escrow.toString());
    if (Number(escrow.price) !== Number(escrowData.price)) {
      throw new Error("Price changed");
    }
    let exx: TransactionInstruction;
    const stakeAddress = await findStakingAccountAddress(
      escrowData.initializerPubkey
    );

    exx = exchangeInstruction(
      escrowData.price,
      user,
      associatedAddress,
      listingID,
      escrow.initializerPubkey,
      stakeAddress,
      escrow.tempTokenAccountPubkey,
      pda,
      mintPublicKey,
      metadataAccountAddress,
      MARKET_FEE_COLLECTOR,
      metadata.data.creators?.map((a) => a.address) ?? []
    );
    transaction.add(exx);
    const res = await this.sendTransaction(user, transaction, options);
    return res;
  }

  async unlist(
    user: PublicKey,
    mintPublicKey: PublicKey,
    listingID: PublicKey,
    options: Options
  ) {
    const transaction = new Transaction();
    const pda = await findPDAAddress();

    const associatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintPublicKey,
      user
    );

    const escrowAccount = await this.connection.getAccountInfo(
      listingID,
      "confirmed"
    );
    if (!escrowAccount) {
      throw new Error("escrow account could not found");
    }
    const escrow = decodeEscrow(escrowAccount.data);
    console.log("escrow", escrow.toString());

    let accountExists = false;
    try {
      const aa = await this.connection.getAccountInfo(
        associatedAddress,
        "confirmed"
      );
      if (aa) {
        accountExists = true;
      }
    } catch (err) {
      console.warn(err);
    }

    if (!accountExists) {
      transaction.add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintPublicKey,
          associatedAddress,
          user,
          user
        )
      );
    }

    const exx = unlistInstruction(
      user,
      associatedAddress,
      listingID,
      escrow.tempTokenAccountPubkey,
      pda,
      mintPublicKey
    );

    transaction.add(exx);
    return this.sendTransaction(user, transaction, options);
  }

  async updateListing(
    user: PublicKey,
    listingID: PublicKey,
    mintId: PublicKey,
    price: number | BN,
    options: Options
  ) {
    const transaction = new Transaction();
    transaction.add(updateListingInstruction(price, user, listingID, mintId));
    return this.sendTransaction(user, transaction, options);
  }

  async offer(
    initializer: PublicKey,
    expireTime: Date,
    mintId: PublicKey,
    priceLamports: number,
    options: Options,
    offerKey?: Keypair
  ) {
    const offerAccount = offerKey ?? Keypair.generate();
    const transaction = new Transaction();

    const minimumAmount =
      await this.connection.getMinimumBalanceForRentExemption(OfferDataSpace);

    if (priceLamports < minimumAmount) {
      throw new Error("Offer price can't be less than Rent Exemption");
    }
    console.log(offerAccount.publicKey.toBase58());

    transaction.add(
      SystemProgram.createAccount({
        fromPubkey: initializer,
        lamports: priceLamports,
        newAccountPubkey: offerAccount.publicKey,
        programId: PROGRAM_ID,
        space: OfferDataSpace,
      })
    );
    transaction.add(
      offerInstruction(
        priceLamports,
        Math.round(expireTime.getTime() / 1000),
        initializer,
        mintId,
        offerAccount.publicKey
      )
    );
    const res = await this.sendTransaction(initializer, transaction, options, [
      offerAccount,
    ]);
    return { ...res, offerID: offerAccount.publicKey };
  }

  async nftOffer(
    initializer: PublicKey,
    expireTime: Date,
    mintId: PublicKey,
    nftsToOffer: PublicKey[],
    options: Options
  ) {
    const seed =
      options.seed ?? Keypair.generate().publicKey.toBase58().substring(0, 8);
    const offerAccount = await findNFTOfferAccountAddress(initializer, seed);

    const nftTokens: PublicKey[] = [];
    for (const t of nftsToOffer) {
      const acc = await this.getTokenLargestAccount(t);
      nftTokens.push(acc);
    }
    const stakeAddress = await findStakingAccountAddress(initializer);
    const transaction = new Transaction();
    transaction.add(
      nftOfferInstruction(
        Math.round(expireTime.getTime() / 1000),
        initializer,
        mintId,
        offerAccount,
        seed,
        stakeAddress,
        nftsToOffer,
        nftTokens
      )
    );
    const res = await this.sendTransaction(
      initializer,
      transaction,
      options,
      []
    );
    return { ...res, offerID: offerAccount };
  }

  async cancelOffer(
    initializer: PublicKey,
    offer: PublicKey,
    options: Options
  ) {
    const offerAccount = await this.connection.getAccountInfo(
      offer,
      "confirmed"
    );
    if (!offerAccount) {
      throw new Error("escrow account could not found");
    }
    const offerData = decodeOffer(offerAccount.data);
    console.log("offer data", offerData.toString());

    const transaction = new Transaction();
    let nftOffer:
      | {
          pda: PublicKey;
          accounts: PublicKey[];
        }
      | undefined;
    if (offerData.tokens.length > 0) {
      const nftTokens: PublicKey[] = [];
      for (const t of offerData.tokens) {
        const acc = await this.getTokenLargestAccount(t);
        nftTokens.push(acc);
      }
      nftOffer = { accounts: nftTokens, pda: await findPDAAddress() };
    }
    transaction.add(cancelOfferInstruction(initializer, offer, nftOffer));
    return this.sendTransaction(initializer, transaction, options);
  }

  async closeOffer(
    closerAndFeePayer: PublicKey,
    offer: PublicKey,
    options: Options
  ) {
    const transaction = new Transaction();
    const offerAccount = await this.connection.getAccountInfo(
      offer,
      "confirmed"
    );
    if (!offerAccount) {
      throw new Error("offer account could not found");
    }
    const offerData = decodeOffer(offerAccount.data);
    console.log("closeing the offer with data", offerData.toString());
    let nftOffer:
      | {
          pda: PublicKey;
          accounts: PublicKey[];
        }
      | undefined;
    if (offerData.tokens.length > 0) {
      const nftTokens: PublicKey[] = [];
      for (const t of offerData.tokens) {
        const acc = await this.getTokenLargestAccount(t);
        nftTokens.push(acc);
      }
      nftOffer = { accounts: nftTokens, pda: await findPDAAddress() };
    }
    transaction.add(
      closeOfferIfExpiredInstruction(
        offerData.initializerPubkey,
        offer,
        closerAndFeePayer,
        nftOffer
      )
    );
    return this.sendTransaction(closerAndFeePayer, transaction, options);
  }

  async acceptNFTOffer(
    user: PublicKey,
    offer: PublicKey,
    mintPubKey: PublicKey,
    offerData: OfferData,
    options: Options
  ) {
    if (offerData.state !== 2) {
      //It is NFT Offer
      throw new Error("it is not an NFT offer");
    }
    console.log("offer", offerData.toString());
    const metadataAccountAddress = await findMetadataAccountAddress(mintPubKey);
    const pda = await findPDAAddress();
    const stakeAddress = await findStakingAccountAddress(user);

    const userAssociatedAddress = await this.getTokenLargestAccount(mintPubKey);
    const initializerAssociatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintPubKey,
      offerData.initializerPubkey
    );

    let initializerAccountExists = false;
    try {
      const aa = await this.connection.getAccountInfo(
        initializerAssociatedAddress,
        "confirmed"
      );
      if (aa) {
        initializerAccountExists = true;
      }
    } catch (err) {
      console.warn(err);
    }
    const transaction = new Transaction();
    if (!initializerAccountExists) {
      transaction.add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintPubKey,
          initializerAssociatedAddress,
          offerData.initializerPubkey,
          user
        )
      );
    }
    const nftTokens: PublicKey[] = [];
    for (const t of offerData.tokens) {
      const acc = await this.getTokenLargestAccount(t);
      nftTokens.push(acc);
    }
    transaction.add(
      acceptNFTOfferInstruction(
        user,
        userAssociatedAddress,
        offerData.initializerPubkey,
        initializerAssociatedAddress,
        offer,
        stakeAddress,
        mintPubKey,
        metadataAccountAddress,
        MARKET_FEE_COLLECTOR,
        pda,
        nftTokens
      )
    );
    transaction.add(
      Token.createCloseAccountInstruction(
        TOKEN_PROGRAM_ID,
        userAssociatedAddress,
        user,
        user,
        []
      )
    );
    return this.sendTransaction(user, transaction, options);
  }

  async acceptOffer(
    user: PublicKey,
    offer: PublicKey,
    mintPubKey: PublicKey,
    options: Options
  ) {
    const offerAccount = await this.connection.getAccountInfo(
      offer,
      "confirmed"
    );
    if (!offerAccount) {
      throw new Error("offer account could not found");
    }
    const offerData = decodeOffer(offerAccount.data);
    if (offerData.state === 2) {
      return this.acceptNFTOffer(user, offer, mintPubKey, offerData, options);
      //It is NFT Offer
    }
    console.log("offer", offerData.toString());

    const metadataAccountAddress = await findMetadataAccountAddress(mintPubKey);
    const metadataAccount = await this.connection.getAccountInfo(
      metadataAccountAddress,
      "confirmed"
    );
    if (!metadataAccount) {
      throw new Error(
        "metadata account could not found: " + metadataAccountAddress.toBase58()
      );
    }

    const metadata = decodeMetadata(metadataAccount.data);
    const userAssociatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintPubKey,
      user
    );
    const initializerAssociatedAddress = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      mintPubKey,
      offerData.initializerPubkey
    );

    let initializerAccountExists = false;
    try {
      const aa = await this.connection.getAccountInfo(
        initializerAssociatedAddress,
        "confirmed"
      );
      if (aa) {
        initializerAccountExists = true;
      }
    } catch (err) {
      console.warn(err);
    }
    const transaction = new Transaction();
    if (!initializerAccountExists) {
      transaction.add(
        Token.createAssociatedTokenAccountInstruction(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintPubKey,
          initializerAssociatedAddress,
          offerData.initializerPubkey,
          user
        )
      );
    }

    const stakeAddress = await findStakingAccountAddress(user);
    let accept = acceptOfferInstruction(
      user,
      userAssociatedAddress,
      offerData.initializerPubkey,
      initializerAssociatedAddress,
      offer,
      stakeAddress,
      mintPubKey,
      metadataAccountAddress,
      MARKET_FEE_COLLECTOR,
      metadata.data.creators?.map((a) => a.address) ?? []
    );
    transaction.add(accept);
    transaction.add(
      Token.createCloseAccountInstruction(
        TOKEN_PROGRAM_ID,
        userAssociatedAddress,
        user,
        user,
        []
      )
    );
    return this.sendTransaction(user, transaction, options);
  }

  async stake(owner: PublicKey, mintPublicKey: PublicKey, options: Options) {
    const stakingAccount = await findStakingAccountAddress(owner);

    const metadataAccountAddress = await findMetadataAccountAddress(
      mintPublicKey
    );
    const accounts = await this.connection.getParsedTokenAccountsByOwner(
      owner,
      {
        mint: mintPublicKey,
      },
      "processed"
    );
    const mm = accounts.value.filter(
      (a) => a.account.data.parsed.info.tokenAmount.uiAmount > 0
    );
    if (mm.length !== 1) {
      throw new Error(FAILED_TO_FIND_ACCOUNT);
    }
    const accountKey = mm[0].pubkey;
    const transaction = new Transaction();

    transaction.add(
      stakeInstruction(
        owner,
        stakingAccount,
        mintPublicKey,
        metadataAccountAddress,
        accountKey,
        options.stakeSlots
      )
    );
    const res = await this.sendTransaction(owner, transaction, options);
    return { ...res };
  }

  async stakeMulti(
    owner: PublicKey,
    mintPublicKeys: PublicKey[],
    options: Options
  ) {
    const stakingAccount = await findStakingAccountAddress(owner);
    const transaction = new Transaction();

    for (const mintPublicKey of mintPublicKeys) {
      const metadataAccountAddress = await findMetadataAccountAddress(
        mintPublicKey
      );
      const accounts = await this.connection.getParsedTokenAccountsByOwner(
        owner,
        {
          mint: mintPublicKey,
        },
        "processed"
      );
      const mm = accounts.value.filter(
        (a) => a.account.data.parsed.info.tokenAmount.uiAmount > 0
      );
      if (mm.length !== 1) {
        throw new Error(FAILED_TO_FIND_ACCOUNT);
      }
      const accountKey = mm[0].pubkey;
      transaction.add(
        stakeInstruction(
          owner,
          stakingAccount,
          mintPublicKey,
          metadataAccountAddress,
          accountKey,
          options.stakeSlots
        )
      );
    }
    const res = await this.sendTransaction(owner, transaction, options);
    return { ...res };
  }

  async unstake(owner: PublicKey, mintPublicKey: PublicKey, options: Options) {
    const stakingAccount = await findStakingAccountAddress(owner);
    const accounts = await this.connection.getTokenLargestAccounts(
      mintPublicKey,
      "processed"
    );
    const mm = accounts.value.filter((a) => (a.uiAmount ?? 0) > 0);
    if (mm.length !== 1) {
      throw new Error(FAILED_TO_FIND_ACCOUNT);
    }
    const accountKey = mm[0].address;
    const transaction = new Transaction();
    transaction.add(
      unstakeInstruction(owner, stakingAccount, mintPublicKey, accountKey)
    );
    const res = await this.sendTransaction(owner, transaction, options);
    return { ...res };
  }

  makeHeader(by: PublicKey, api: AppAPIs) {
    const data = { ...api };
    (data as any)["by"] = by.toBase58();
    const req = JSON.stringify(data);
    const b = Buffer.from(req, "utf8");
    return base58.encode(b);
  }
}
